import * as React from "react"
import LoginHeader from "../LoginHeader"
import Categories from "../PlatformCategories/Categories"
import PopularApps from "../PlatformCategories/PopularApps"
import RecentlyAdded from "../PlatformCategories/RecentlyAdded"
import ComingSoon from "../PlatformCategories/ComingSoon"
// import Text from "../../atoms/Text"
import ViewComp from "../../molecules/ViewComp"
import ChipsContainer from "../ChipsContainer"
import Link from '../../atoms/Link'

const Iplatform = () => {
  const [view, setViewType] = React.useState<"list" | "grid">("grid");
  const Header = () => (
    <div className="md:flex block w-full justify-between items-center md:mt-16 mt-12">
      {/* <p className="hidden lg:block text-charcoal text-xl">FILTERS:</p>
      <Text type="H3" className="text-viking">Results for: <span className="text-charcoal">All 18 apps</span></Text> */}
      <Link type='internal' to="/products/iplatform/search">
        <button className="hover:bg-viking hover:text-white hover:border-viking md:w-auto md:px-16 block text-viking border border-viking rounded-full py-2 w-full my-6 ">Filter</button>
      </Link>
      <ViewComp setView={setViewType} activeView={view} />
    </div>
  );

  return (
    <div>
      <LoginHeader />
      <ChipsContainer showFilter={false} />
      <Categories CustomHeader={Header} view={view} grid_3={true} />
      <PopularApps view={view} />
      <RecentlyAdded view={view} />
      <ComingSoon view={view} />
    </div>
  )
}

export default Iplatform
