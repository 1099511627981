import React from 'react';
import Text from '../../atoms/Text';
import { IHighlight } from '../../../types/components'
import PortableText from '../../atoms/PortableText'

const Highlight: React.FC<IHighlight> = ({ className='', title, body, subtitle, ...rest }) => {

  return (
  <article className={`text-center px-10 highlight ${className}`} {...rest}>
    <Text type="H4" className="text-viking lg:text-5xl md:text-2xl text-3xl whitespace-nowrap">{title}</Text>
      {body && <PortableText className="inline text-charcoal" content={body} template={{
        normal: (props: any) => <Text type="H4" className={`text-viking ${String(props?.children).length > 6 && !String(props?.children).includes('[object Object]') ? 'lg:text-3xl lg:mb-5' : 'lg:text-5xl'} md:text-2xl text-3xl whitespace-nowrap`}>{props?.children}</Text>,
        strong: (props: any) => <sub className="font-montserrat  text-3xl md:text-xl lg:text-3xl bottom-0">{props?.children}</sub>,
      }} />}
    <Text type="P" ptype="sm-bold" className="text-blue-stone uppercase text-base mt-2 font-bold">{subtitle}</Text>
  </article>
)
}

export default Highlight