import _sanityClient from '@sanity/client';
import imageUrlbuilder from '@sanity/image-url'

const sanityClient = _sanityClient({
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_STUDIO_API_DATASET,
  apiVersion: '2021-08-09',
  useCdn: true
});

const builder = imageUrlbuilder(sanityClient);

export const imageURLBuilder = (source: any) => builder.image(source);
