import React, { useState } from 'react'
import Location from '../../atoms/svg/Location'
import Email from '../../atoms/svg/Email'
import Clock from '../../atoms/svg/Clock'
import Phone from '../../atoms/svg/Phone'
import Sales from '../../atoms/svg/Sales'
import Link from '../../atoms/Link'
import Text from '../../atoms/Text'

import './formStyle.css'
import Checkbox from '../../atoms/Checkbox'
import { useToastFuncs } from '../../../Context/ToastContext'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const _data = {
  fullname: '',
  email: '',
  tel: '',
  means_of_communication: 'Video chat',
  message: '',
}

const GeneralEnquiryForm: React.FC = () => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [showMessage, setShowMessage] = useState(false)
  const [data, setData] = useState(_data);
  const [agree, setAgree] = useState<boolean>(false);
  const Toast = useToastFuncs();

  const handleChange =  (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const value = e.target.value;
    const id = e.target.id;
    setData(prev => ({ ...prev, [id]: value }))
  }

  const handleSubmit = async (event: React.SyntheticEvent) => {

    event.preventDefault()

    if (!executeRecaptcha) {
      Toast?.error && Toast.error('reCAPTCHA not yet available')
      return
    }
    const token = await executeRecaptcha('enquiry')

    const url = 'https://n8n.wobot.workshoporange.co/webhook/2bd3b2ac-8ad8-4a8c-9095-77bd4fe04b35'

    const formData = {
      ...data,
      type: 'General Enquiry Form',
      token
    }

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(formData),
      headers: new Headers()
    })
      .then(() => {
        setData(_data);
        setShowMessage(true);
        Toast?.success && Toast.success('Your form has been submitted, we will contact you shortly')
      })
      .catch((error) => {
        console.log('error', error);
        Toast?.error && Toast.error('Operation failed. Try again')
      })
  }
  
  

  return (
    <section className="max-w-screen-xxl xl:w-6/12 lg:w-8/12 md:w-7/12 w-11/12 mx-auto my-10 grid md:grid-cols-1 lg:grid-cols-5 py-10">
      {showMessage ? <div className="py-3 px-4 bg-waikawa-grey flex items-center justify-between bg-viking p-5 text-white my-5 lg:col-span-5">
          <Text type="P" ptype="p-base" className="mb-0">
            Your form has been submitted, we will contact you shortly
          </Text>
        </div> : 
        <form className="lg:col-span-3" onSubmit={handleSubmit}>
          <div className="relative my-3 pt-4">
            <input type="text" onChange={handleChange} value={data.fullname} id="fullname" className="border-0 border-b-2 border-black border-opacity-80 outline-none transition-all duration-1000 w-full h-12 focus:border-deluge px-4 placeholder-transparent" required placeholder="Enter your name" />
            <label htmlFor="fullname" className="cursor-text transition-all duration-1000 text-casper" aria-required>Enter your name</label>
            <Text type="P" ptype="sm-light" className="text-right text-bombay py-0 italic">Required</Text>
          </div>
          <div className="relative my-3 pt-4">
            <input type="email" value={data.email} onChange={handleChange} id="email" className="border-0 border-b-2 border-black border-opacity-80 outline-none transition-all duration-1000 w-full h-12 focus:border-deluge px-4" required placeholder="Enter your email address" />
            <label htmlFor="email" className="cursor-text transition-all duration-1000 text-casper" aria-required>Enter your email address</label>
            <Text type="P" ptype="sm-light" className="text-right text-bombay py-0 italic">Required</Text>
          </div>
          <div className="relative my-3 pt-4">
            <input onChange={handleChange} value={data.tel} type="tel" id="tel" className="border-0 border-b-2 border-black border-opacity-80 outline-none transition-all duration-1000 w-full h-12 focus:border-deluge px-4" required placeholder="Enter your phone number" />
            <label htmlFor="tel" className="cursor-text transition-all duration-1000 text-casper" aria-required>Enter your phone number</label>
            <Text type="P" ptype="sm-light" className="text-right text-bombay py-0 italic">Required</Text>
          </div>
          <div className="relative my-3 pt-4">
            <select onChange={handleChange} required value={data.means_of_communication} id="means_of_communication" className="border-0 border-b-2 border-black border-opacity-80 outline-none transition-all duration-1000 w-full h-12 focus:border-deluge px-4">
              <option value="***" disabled>Select one</option>
              <option value="Video chat">Video chat</option>
              <option value="Phone call">Phone call</option>
              <option value="Email">Email</option>
              <option value="WhatsApp">WhatsApp</option>
              <option value="Other">Other</option>
            </select>
            <label htmlFor="service" className="-top-6 left-0 right-0 flex absolute items-center cursor-text transition-all duration-1000 h-12 text-casper py-2" aria-required>Preferred means of Communication</label>
            <Text type="P" ptype="sm-light" className="text-right text-bombay py-0 italic invisible">Optional</Text>
          </div>
          <div className="relative my-3 pt-4">
            <textarea onChange={handleChange} value={data.message} id="message" rows={5} required placeholder="I’m enquiring about" className="border-0 border-b-2 border-black border-opacity-80 outline-none transition-all duration-1000 w-full focus:border-deluge px-4"></textarea>
            <label htmlFor="message" className="cursor-text transition-all duration-1000 text-casper" aria-required>I’m enquiring about</label>
            <Text type="P" ptype="sm-light" className="text-right text-bombay py-0 italic">Required</Text>
          </div>
          
          <div className="mb-4">
            <Checkbox
              name="agreement" 
              value="" 
              handleChange={() => {
                setAgree(prev => !prev);
              }} 
              label="I agree to be contacted"
              type="checkbox"
              defaultChecked={agree}
              />
          </div>
          
          <div className="text-center">
            <button disabled={!agree} type="submit" className={`py-1 px-6 rounded-full text-white bg-gradient-to-r from-blue-stone to-viking ${agree ? "" : "opacity-40"}`} >Send Enquiry</button>
          </div>
        </form>
      }
      <div className="py-10 md:ml-10 lg:col-span-2 text-dove-grey">
        <div className="mr-10">
          <div className="flex items-start mb-6">
            <div className="mr-2"><Location /></div>
            <p><b><u>Cardinal South Africa</u></b><br />First Floor Block A, 28 on Sloane Office Park, 28 Sloane Street, Bryanston, Johannesburg</p>
          </div>

          <div className="my-16">
            <div className="flex items-start mb-6">
              <Link type="external" to="tel:+27113001100" className="pb-3 flex items-center"><span className="pr-2"><Phone /></span> +27 11 300 1100</Link>
            </div>

            <div className="flex items-start mb-6">
              <Link type="external" to="mailto:info@cardinal.co.za" target="_blank" className="py-3 flex items-center"><span className="pr-2"><Email /></span> info@cardinal.co.za</Link>
            </div>

            <div className="flex items-start mb-6">
              <div className="mr-2"><Clock /></div>
              <p>Mon-Fri: 8am-5pm</p>
            </div>
          </div>
        </div>

        <div className="my-20 shadow-fullSides p-6 w-full">
          <div className="flex items-center mb-6">
            <div className="mr-5"><Sales /></div>
            <Text type="H3" className="text-viking font-normal text-3xl">Sales</Text>
          </div>
          <div className="flex items-start mb-6">
            <div>
            <Link type="external" to="tel:+27113001100" className="pb-3 flex items-center"><span className="pr-2"><Phone /></span> +27 11 300 1100</Link>
            <Link type="external" to="tel:+27833105340" className="pb-3 flex items-center"><span className="pr-2"><Phone /></span> +27 83 310 5340</Link>
            </div>
          </div>

          <div className="flex items-start mb-6">
            <Link type="external" to="mailto:info@cardinal.co.za" target="_blank" className="py-3 flex items-center"><span className="pr-2"><Email /></span> info@cardinal.co.za</Link>

          </div>

          <div className="flex items-start mb-6">
            <div className="mr-2"><Clock /></div>
            <p>Mon-Fri: 8am-5pm</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default GeneralEnquiryForm