import React, { useState }  from 'react'
import Text from '../../atoms/Text'
import MaxWidth from '../../molecules/Maxwidth'
import MessageSent from '../../atoms/svg/MessageSent'
import Button from '../../atoms/Button'
import Checkbox from '../../atoms/Checkbox'
import Link from '../../atoms/Link'
import PortableText from '../../atoms/PortableText'
import { INewsletter } from '../../../types/components'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { useToastFuncs } from '../../../Context/ToastContext'

const NewsletterDefault: React.FC<INewsletter> = ({ className = '', imageUrl, body, iconHint }) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [email, setEmail] = useState('');
  const [showMessage, setShowMessage] = useState(false)
  const [agree, setAgree] = useState<boolean>(false)
  const Toast = useToastFuncs();

  const renderIcon = () => {
    switch (iconHint) {
      case 'subscribe-icon':
      return <MessageSent />

      default:
      return <>{iconHint} is not available</>
    }
  }


  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault()
    if (!executeRecaptcha) {
      Toast?.error && Toast.error('reCAPTCHA not yet available')
      return
    }
    const token = await executeRecaptcha('newsletter')
    const url = 'https://n8n.wobot.workshoporange.co/webhook/07aff596-57a3-465b-9f17-0254feb24643'
    const formData = {
      email,
      type: 'Newsletter subscription',
      token
    }

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(formData),
      headers: new Headers()
    })
      .then(() => {
        setEmail('');
        setShowMessage(true)
      })
      .catch((error) => {
        console.log('error', error);
      })
    setEmail('');
    setShowMessage(true)
  }

  return (
    <MaxWidth className={`w-full py-10 lg:px-8 px-2 overflow-hidden bg-center bg-cover bg-no-repeat my-10  ${className}`} style={{ 'backgroundImage': `url(${imageUrl})` }}>
      <div className="lg:w-11/12 mx-auto md:flex items-center">
        <article className="mx-10 mb-7 md:mb-0">
          <div className="bg-white rounded-full w-48 h-48 flex justify-center items-center">
            {renderIcon()}
          </div>
        </article>
        <article className="bg-blue-stone bg-opacity-80 flex flex-col justify-between py-10 px-11 rounded-sm text-white flex-1">
          {body ? <PortableText className="inline" content={body} template={{
            normal: (props: any) => <Text type="P" ptype="p-base" className="font-medium">{props?.children}</Text>,
            h2: (props: any) => <Text type="P" ptype="p-base" className="uppercase tracking-wide text-2xl">{props?.children}</Text>,
            h3: (props: any) => <Text type="H3" className="uppercase tracking-wide text-2xl">{props?.children}</Text>,
          }} /> : ''}

          {showMessage ? <Text type="P" ptype="p-base" className="my-5">You've been added to our subscription list!</Text>: 
            <>
              <form className="lg:flex" method="post" onSubmit={handleSubmit}>
                <div className="items-center flex-1 mb-3 lg:mb-0">
                  <input type="email" placeholder="Your email" required className="border-b-2 border-white placeholder-white bg-blue-stone bg-opacity-70 px-4 py-1 text-sm w-full" value={email} onChange={(e) => setEmail(e.target.value)} />
                  <Text type="P" ptype="sm-light" className="text-right">Required</Text>
                </div>
                <div className="lg:ml-10">
                  <Button size="btn-small" type="submit" color="btn-default" disabled={!agree} className="text-blue-stone text-opacity-80 rounded-full py-1 transition-all duration-300 hover:bg-transparent hover:text-white border border-white disabled:cursor-not-allowed disabled:border-gray-500 disabled:bg-gray-400 disabled:text-white">Submit</Button>
                </div>
              </form>
              <div className="mb-4 text-white">
                <Checkbox
                  name="agreement"
                  className="border-white"
                  handleChange={() => {
                    setAgree(prev => !prev);
                  }}
                  type="checkbox"
                defaultChecked={agree}><span className="text-white text-xs">I have read Cardinal's <Link to="/privacy-policy" className="underline">Privacy Policy</Link> and agree to be contacted</span> </Checkbox>
              </div>
            </>
          }
        </article>
      </div>
    </MaxWidth>
  )
}

export default NewsletterDefault