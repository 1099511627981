import React from "react"
import { useAppListContext } from "../../../Context/AppListContext";
import { useIPlatformContext } from "../../../Context/IPlatformContext"
import { useEnquiryCheckout } from "../../../hooks/useEnquiryCheckout";
import Input from "../../atoms/Input"
import Text from "../../atoms/Text";
import MessageSent from "../../atoms/svg/MessageSent";
import Demo from "../../atoms/svg/Demo";
import Checkbox from "../../atoms/Checkbox";
import { useToastFuncs } from "../../../Context/ToastContext";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';


const EnquiryForm = ({ type }: { type?: string}) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const { getCountries } = useIPlatformContext();
  const { getApiData, clearCart } = useAppListContext();
  const { submitEnquiry, loading, response, setResponse, error } = useEnquiryCheckout();

  const defaultValue = {
    fullname: "",
    email: "",
    phone: "",
    meansOfCommunication: "",
    businessName: "",
    additionalInformation: "",
    businessType: "",
    country: "",
    natureOfBusines: "",
    annualGross: "",
    noOfEmployees: "",
    ...(type === "demo" ? {enquiryMessage: ""} : {}),
  };

  const [enquiryForm, setEnquiryForm] = React.useState(defaultValue);
  const [localError, setLocalError] = React.useState<string>("");
  const [showSuccessMessage] = React.useState<boolean>(false);
  const [agree, setAgree] = React.useState<boolean>(false);
  const Toast = useToastFuncs();

  React.useEffect(() => {
    if (response && enquiryForm?.email) {
      setEnquiryForm(defaultValue);
      clearCart?.();
      setResponse(null);
      // setShowSuccessMessage(true);
      Toast.success && Toast.success("Thank you for your Message. The Cardinal Team will be in contact to set up a demo");
    }
  }, [response, enquiryForm]);

  React.useEffect(() => {
    if (error) {
      setLocalError("Operation Failed. Please try again.")
      Toast.error && Toast.error("Operation Failed. Please try again.");
    }
  }, [error])

  const handleChange = ({
    target: { name, value },
  }: {
    target: { name: string; value: string }
  }) => {
    setEnquiryForm({
      ...enquiryForm,
      [name]: value,
    })
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!executeRecaptcha) {
      Toast?.error && Toast.error('reCAPTCHA not yet available')
      return
    }
    
    const token = await executeRecaptcha('demo')
    const appList = getApiData?.();
    
    if (type !== 'demo' && !appList?.length) {
      setLocalError("Please add Apps to the list");
      return;
    }
    if (appList) {
      submitEnquiry(enquiryForm,
        appList,
        token
      );
    }
  };
  
 const buttonText = type === 'demo' ? 'Send Enquiry' : 'Book a Demo';
 const buttonLoading = type === 'demo' ? 'Sending...' : 'Booking...';
  return (
    <>
      {showSuccessMessage ?
        (<div className="w-full mt-12 bg-light-green py-12">
        <div className="max-w-lg w-full border border-solid bg-white border-gray-200 p-8 flex flex-col items-center justify-center mx-auto">
          <MessageSent />
          <Text  type="H3" className="m-0 py-4 text-2xl text-viking">Thank you for your Message</Text>
          <Text type="P" ptype="sm-light" className="my-4 mt-0 mx-auto">
           The Cardinal Team will be in contact to set up a demo 
          </Text>
        </div>
        </div>)

        : (<div className="flex flex-col">
          <form onSubmit={handleSubmit} className="flex py-4 px-4 items-center justify-center flex-col my-4 w-full">
            <div className="flex w-full items-center justify-center py-4 pt-0">
              <Demo />
              <Text type="H2" className="m-0 ml-4 text-charcoal">
                Book a Demo
              </Text>
            </div>      
            <div className="my-10 mb-0 w-full max-w-md mx-auto">
              <Input
                required
                value={enquiryForm.fullname}
                name="fullname"
                onChange={handleChange}
                type="text"
                placeholder="Enter your name"
              />
              <Input
                required
                value={enquiryForm.email}
                name="email"
                onChange={handleChange}
                type="text"
                placeholder="Enter your email"
              />
              <Input
                required
                value={enquiryForm.phone}
                name="phone"
                onChange={handleChange}
                type="text"
                placeholder="Enter your phone number"
              />
              <Input
                type="select"
                required
                value={enquiryForm.meansOfCommunication}
                options={[
                  { label: "Video Chat", "value": "video chat" },
                  { label: "Phone call", "value": "phone call" },
                  { label: "Email", "value": "email" },
                  { label: "WhatsApp", "value": "Whatsapp" },
                  { label: "Other", "value": "other" }
                ]}
                name="meansOfCommunication"
                onChange={handleChange}
                placeholder="Preferred means of Communication"
              />
              {type === 'demo' && <Input
                required
                value={enquiryForm.enquiryMessage || ''}
                name="enquiryMessage"
                onChange={handleChange}
                type="select"
                options={[
                  { label: "C360", "value": "C360" },
                  { label: "C360i", "value": "C360i" },
                ]}
                placeholder="I'm enquiring about"
              />}
              {/* <Input
                required
                value={enquiryForm.additionalInformation}
                name="additionalInformation"
                onChange={handleChange}
                type="text"
                placeholder=""
              /> */}
              <Text type="P" ptype="sm-bold" className="my-8 text-viking mx-auto">
                Please share the following additional information to allow us to set
                up a relevant and customised app demo for you:
              </Text>
              <Input
                required
                value={enquiryForm.businessName}
                name="businessName"
                onChange={handleChange}
                type="text"
                placeholder="Business Name"
              />
              <Input
                required
                value={enquiryForm.businessType}
                name="businessType"
                options={[
                  { label: "Insurer", value: "insurer" },
                  { label: "UMA", value: "UMA" },
                  { label: "MGA", value: "MGA" },
                  { label: "Brokerage", value: "brokerage" },
                  { label: "Administrator", value: "administrator" },
                ]}
                onChange={handleChange}
                type="select"
                placeholder="Business Type"
              />
              <Input
                required
                value={enquiryForm.country}
                name="country"
                options={getCountries?.()?.map((country: string) => ({ label: country, value: country }))}
                onChange={handleChange}
                type="select"
                placeholder="Country"
              />
              <Text type="P" ptype="sm-light" className="my-4 mt-0 mx-auto">
                Describe the nature of your business and what you are looking for :
              </Text>
              <Input
                value={enquiryForm.additionalInformation}
                name="additionalInformation"
                onChange={handleChange}
                type="text"
                placeholder=""
              />
              <Input
                required
                value={enquiryForm.annualGross}
                name="annualGross"
                onChange={handleChange}
                type="text"
                placeholder="Annual Gross Written Premium"
              />
              <p className="w-full pt-4 pb-0 px-3 pl-0 m-0 border-0 border-solid border-light-black focus:border-gray-500 border-b-1 outline-none bg-transparent text-gray-400" >
              Number of employees
              </p>
              <div className="checkboxes flex w-full mb-8 mt-0 flex-wrap justify-center">
                <Checkbox 
                inpVal={enquiryForm.noOfEmployees}
                  name="noOfEmployees" 
                  value="< 50" 
                  handleChange={handleChange} 
                  label="< 50"
                  />
                  <Checkbox 
                inpVal={enquiryForm.noOfEmployees}
                  name="noOfEmployees" 
                  value="50 - 150" 
                  handleChange={handleChange} 
                  label="50 - 150"
                  />
                  <Checkbox 
                inpVal={enquiryForm.noOfEmployees}
                  name="noOfEmployees" 
                  value="150 - 300" 
                  handleChange={handleChange} 
                  label="150 - 300"
                  />
                  <Checkbox 
                inpVal={enquiryForm.noOfEmployees}
                  name="noOfEmployees" 
                  value="300 +" 
                  handleChange={handleChange} 
                  label="300 +"
                  />
              </div>
            </div>
            <Checkbox 
                name="agreement" 
                value="" 
                handleChange={() => {
                  setAgree(prev => !prev);
                }} 
                label="I agree to be contacted"
                type="checkbox"
                defaultChecked={agree}
            />
            <button
              disabled={!agree}
              color="btn-primary"
              className={`rounded-3xl py-2 px-12 mx-auto my-4 mt-8 bg-blue-stone text-white ${agree ? '' : "opacity-30"}`}
            >
              {loading ? buttonLoading : buttonText}
            </button>
            {localError && <Text type="P" ptype="sm-bold" className="mx-auto pt-8  font-normal text-red-600">{localError}</Text>}
          </form>
        </div>)}
    </>
  )
}

export default EnquiryForm
