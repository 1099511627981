import React from 'react'
import { useIPlatformContext } from '../../../Context/IPlatformContext';
import Link from '../../atoms/Link';
import Text from '../../atoms/Text';
import Container, { CategoryContainerProps } from './index';


const ComingSoon = ({ view }: CategoryContainerProps) => {
  const { getComingSoonApp } = useIPlatformContext();
  const Header = () => <div className="flex w-full justify-between items-center"><Text type="H2" className="my-0">Coming soon</Text> <Link to="/products/iplatform/search"><Text type="H4" className="my-0">SEE ALL</Text></Link></div>
  return (
    <div className="bg-gray-100">
      <Container className="py-8" Header={Header} records={getComingSoonApp?.()} view={view} grid_3={true} />
    </div>
  )
}

export default ComingSoon;
