import React from 'react'
import Text from '../../atoms/Text'
import { ReadNextType } from '../../../types'
import { ContentPageCard } from '../../molecules/Card'
import PortableText from '../../atoms/PortableText'
import { replaceLinks } from '../../../utils/service'



const ReadNext: React.FC<ReadNextType> = ({ portableText = null, pages }) => {

  return (
    <section className="max-w-screen-xxl lg:w-6/12 md:w-9/12 w-11/12 mx-auto my-10">
      {portableText ? <PortableText className="text-lg font-semibold" content={portableText} template={{
        normal: (props: any) => <Text type="H3" className="text-2xl lg:w-4/12 font-light leading-normal text-charcoal">{props.children}</Text>,
        h2: (props: any) => <Text type="H2" className="text-2xl lg:w-4/12 leading-normal font-light text-charcoal">{props.children}</Text>,
        h3: (props: any) => <Text type="H3" className="text-2xl lg:w-4/12 leading-normal font-light text-charcoal mb-2">{props.children}</Text>,
        h1: (props: any) => <Text type="H1" className="text-2xl lg:w-4/12 leading-normal font-light text-charcoal">{props.children}</Text>,
        strong: (props: any) => <strong className="text-2xl lg:w-4/12 leading-normal font-medium text-charcoal">{props.children}</strong>,
      }} /> : ''}

      <section className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4">
        {pages ? pages.map((page: any, index: number | string) => (
          <ContentPageCard linkTo={replaceLinks(`/${page?.page?.slug?.current}`)} key={index}>{page?.linkTitle}</ContentPageCard>
        ))
        :''}
      </section>
    </section>
  )
};

export default ReadNext;
