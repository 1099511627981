import React from 'react';
import { PagePart } from '../../common/types';
import HeroSection from '../../common/components/organism/Hero';
import SimpleContentBlock from '../../common/components/organism/SimpleContentBlock';
import CardCollection from '../../common/components/organism/CardCollectionStyle';
import CardWide from '../../common/components/organism/CardWide';
import ReadNext from '../../common/components/organism/ReadNext';
import TabContainer from '../../common/components/organism/TabContainer';
import SimpleContentGrid from '../../common/components/organism/SimpleContentGrid';
import FormPart from '../../common/components/organism/FormPart';
import IPlatform from '../../common/components/organism/IPlatform';
import IPlatformSearch from '../../common/components/organism/IPlatform/search';
import IPlatformEnquire from '../../common/components/organism/IPlatform/enquire';
import ProductList from '../../common/components/organism/ProductList';
import YouTube from '../../common/components/molecules/Video';
import './style.css';

interface IProp {
  part: PagePart,
  id: number
}

const PagePartRenderer: React.FC<IProp> = ({ part, id }) => {

  switch(part._type)
    {
        case "hero":
        return <HeroSection heroSize={part?.heroSize!} imageUrl={part?.image?.asset?.url!} portableText={part?.body} key={part._key} />

        case "simpleContentBlock":
        return <SimpleContentBlock portableText={part?.body} key={part._key} navanchor={part?.navanchor} image={part?.image || null} imagePlacement={part?.image_placement} />

        case "youtube":
        return <div className="my-8" ><YouTube videoUrl={part?.url || ''} title={part?.title || ''}
         options={{ 
          autoplay: !!part?.autoplay,
          controls: !!part?.controls, 
          loop: !!part?.loop, 
          modestbranding: !!part?.modestbranding, 
          disablekb: !!part?.disablekb }} 
        key={part._key} />
        </div>
        
        case "readNextPart": 
        return <ReadNext portableText={part?.body} key={part?._key} pages={part?.pages} />

        case "cardCollection":
        return <CardCollection heading={part?.heading || ''}  cardCollectionStyle={part?.cardCollectionStyle} cards={part?.cards || []} key={part?._key} navanchor={part?.navanchor}  />

        case "cardWide":
        return <CardWide image={part?.image} iconHint={part.iconHint} button={part.button} body={part.body} cardStyle={part.cardStyle}  key={part._key} navanchor={part?.navanchor} />
        
        case "tabContainerPart":
        return <TabContainer tabs={part?.tabs} />

        case "simpleContentGrid":
        return <SimpleContentGrid key={part._key} body={part.body} contentBlocks={part.contentBlocks} navanchor={part?.navanchor} />
        
        case "formPart":
        return <FormPart key={part._key} simpleForm={part?.simpleForm} />

        case "C360ProductList":
        return <ProductList key={part._key} />

        case "iPlatformLandingPart":
        return <IPlatform />

        case "iPlatformSearchPart":
        return <IPlatformSearch />

        case "iPlatformEnquirePart":
        return <IPlatformEnquire />

        default:
            return <div key={id} className="border-red-500 border">Missing Component Handler for type {part._type} </div>
    }
};

export default PagePartRenderer;
