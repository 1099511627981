import React, { useState, useEffect } from 'react'
import PagePartRenderer from '../../../../modules/PagePartRender'
import { TabsType } from '../../../types'

import { useLocation } from "@reach/router"
interface ITabContainer {
  tabs?: TabsType[]
}

const TabContainer: React.FC<ITabContainer> = ({ tabs }) => {

  const location = useLocation();
  const [activeTab, setActiveTab] = useState('demo')

  useEffect(() => {
    if (tabs?.length) {
      setActiveTab(tabs?.[0].navanchor!)
    }
    return () => {
      setActiveTab('')
    };
  }, [tabs])

  useEffect(() => {
    const locationHash = location.hash.split('#')[1]
    if (locationHash) {
      setActiveTab(locationHash)
    }
  }, [location])

  const setTabChange = (key: string) => {
    setActiveTab(key)
  }

  return (
    <section className="">
      <div className="shadow-xl mb-10">
        <div className={`overflow-x-scroll max-w-screen-xxl lg:w-6/12 w-11/12 
        mx-auto mt-10 cursor-pointer flex justify-between items-end text-charcoal`} id={activeTab}>
          {tabs?.map((tab, index) =>
            <div
              // to={`#${tab.navanchor}`}
              key={index}
              onClick={() => setTabChange(tab.navanchor!)}
              className={`w-auto sm:w-1/5 flex-grow flex-shrink text-xxs 2xl:text-xl lg:text-xs mb-0 text-center transition-all duration-300 py-3 px-2 border-b-4 border-transparent ${activeTab === tab.navanchor ? 'text-viking border-viking' : ''}`}>{tab.name}
          </div>)}
        </div>
      </div>
      <div className="my-10">
        {tabs?.map((tab, index) => tab.navanchor === activeTab && (
          <section key={index}>
            {tab?.tabParts?.map((part, i) => <PagePartRenderer part={part} key={i} id={i} />)}
          </section>
        ))}
      </div>
    </section>
  )
};

export default TabContainer;
