import React from 'react'
import { SimpleFormType } from '../../../types'
import EnquiryForm from '../EnquiryForm'
import GeneralEnquiryForm from './GeneralEnquiryForm'

interface IFormPart {
  simpleForm?: SimpleFormType
}

const FormPart: React.FC<IFormPart> = ({ simpleForm }) => {

  const renderForm = () => {
    switch (simpleForm?.formId) {
      case 'general-enquiries-form':
        return <GeneralEnquiryForm />
      case 'book-a-demo-form':
        return <EnquiryForm type="demo" />
    
      default:
        return `form type '${simpleForm?.formId}' isn't available`
    }
  }


  return (
    <>
      {renderForm()}
    </>
  )
}

export default FormPart