import React from 'react'
import { CardWideType } from '../../../types'
import Contact from '../../molecules/Contact'
import CaseStudy from "../../molecules/CaseStudy"
import SecondaryCTA from '../SecondaryCTA'
import NewsletterDefault from '../Newsletter'
import BlockContentWithIcon from '../../molecules/BlockContentWithIcon'
import { imageURLBuilder } from '../../../utils'

const CardWide: React.FC<CardWideType> = (prop) => {

  switch(prop.cardStyle) {
    case 'strip-call-to-action':
      return <Contact iconHint={prop.iconHint} button={prop.button} body={prop.body} />

    case 'bold-call-to-action':
      return <CaseStudy imageUrl={imageURLBuilder(prop?.image).url()} title={prop?.title} button={prop?.button} body={prop?.body}  />
      
    case 'case-study-card':
      return <CaseStudy imageUrl={imageURLBuilder(prop?.image).url()} button={prop?.button} body={prop?.body}  />

    case 'secondary-call-to-action':
      return <SecondaryCTA iconHint={prop.iconHint} body={prop.body} button={prop.button} navanchor={prop?.navanchor} />

    case 'subscribe-call-to-action':
      return <NewsletterDefault imageUrl={imageURLBuilder(prop?.image).url()} body={prop?.body} iconHint={prop?.iconHint} />
    
    case 'simple-icon-with-text':
      return <BlockContentWithIcon iconHint={prop.iconHint} portableText={prop.body} navanchor={prop.navanchor} />

    default:
      return <>No wide-card style for {prop.cardStyle} </>
  }
};

export default CardWide;
