import React from 'react'
import Link from '../../atoms/Link'
import { CardWideType } from '../../../types'
import ContactUS from '../../atoms/svg/ContactUs'
import Demo from '../../atoms/svg/Demo'
import PortableText from '../../atoms/PortableText'
import { replaceLinks } from '../../../utils/service'

interface ISecondaryCTA {
  button: CardWideType['button']
  body: CardWideType['body']
  iconHint: CardWideType['iconHint']
  navanchor?: string | null
}


const SecondaryCTA: React.FC<ISecondaryCTA> = ({ body, iconHint, button, navanchor }) => {
  
  const renderIcon = () => {
    switch (iconHint) {
      case 'mobi-contact':
        return <ContactUS />

      case 'demo-contact':
        return <Demo />

      default:
        return <>{iconHint} is not available</>
    }
  }

  return (
    <section className="max-w-screen-xxl lg:w-6/12 md:w-7/12 w-11/12 mx-auto md:my-16 my-5 md:flex items-center md:justify-start justify-center">
      {iconHint ? renderIcon() : ''}

      <div className="md:ml-3">
        <Link to={replaceLinks(`/${button?.buttonLink?.slug?.current}${navanchor ? `/#${navanchor}`: ''}`)} className="text-viking underline text-2xl font-light leading-normal inline-block mr-2">{button?.buttonText}</Link>
        <PortableText className="inline" content={body} template={{
          normal: (props: any) => <span className="text-2xl font-light leading-normal text-charcoal mb-0">{props?.children}</span>,
        }} />
      </div>
      
    </section>
  )
};

export default SecondaryCTA;
