import React from 'react'

export default function SearchGrey({ fill = "#545454" }) {
  return (
    <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>icon/search-grey</title>
      <g id="-Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Top-navigation/Teal-breadcrumb" transform="translate(-1249.000000, -46.000000)" fill={fill} fillRule="nonzero">
          <g id="icon/search-grey" transform="translate(1249.000000, 46.000000)">
            <path d="M19.7784339,18.8813703 L14.6230995,13.8084176 C15.9731158,12.3416639 16.8026101,10.4018597 16.8026101,8.26730832 C16.8019576,3.7011093 13.0409135,0 8.40107667,0 C3.7612398,0 0.000195758564,3.7011093 0.000195758564,8.26730832 C0.000195758564,12.8335073 3.7612398,16.5346166 8.40107667,16.5346166 C10.4058075,16.5346166 12.2445024,15.8412072 13.6887765,14.6884176 L18.8641109,19.7813703 C19.1162806,20.0297879 19.525677,20.0297879 19.7778467,19.7813703 C20.0306036,19.5329853 20.0306036,19.1297879 19.7784339,18.8813703 Z M8.40107667,15.2626427 C4.47526917,15.2626427 1.29278956,12.1307341 1.29278956,8.26730832 C1.29278956,4.40388254 4.47526917,1.2719739 8.40107667,1.2719739 C12.3269168,1.2719739 15.5093638,4.40388254 15.5093638,8.26730832 C15.5093638,12.1307341 12.3269168,15.2626427 8.40107667,15.2626427 Z" id="Shape"></path>
          </g>
        </g>
      </g>
    </svg>
  )
}
