import React from 'react';
import { Card, CardCollectionType } from '../../../types';
import Highlight from '../../molecules/Highlight';
import { LogoOnlyContentCard, OverviewCardWithLink, FeaturedCard, TitleWithContentCard, TitleOnlyContentCard, GroupCard } from '../../molecules/Card';
import { imageURLBuilder } from '../../../utils';
import Text from '../../atoms/Text';
import Maxwidth from '../../molecules/Maxwidth'

interface IProp {
  cardCollectionStyle: CardCollectionType
  cards: Card[]
  heading: string
  navanchor?: string | null
}

const CardCollectionStyle: React.FC<IProp> = ({ cardCollectionStyle, cards, heading, navanchor }) => {

  switch(cardCollectionStyle)
    {
      case "numbers-grid":
       return (
         <div className="max-w-screen-xxl lg:w-6/12 md:w-9/12 w-11/12 mx-auto border-t border-b py-5 grid md:grid-cols-3 gap-y-10" id={navanchor || ''}>
          {cards.map((item, i) =>
            <Highlight title={item?.heading || ''} subtitle={item?.subheading || ''} key={i} body={item?.body!} />
          )}
        </div>
      )

      case "overview-card-with-links-collection":
       return (
         <div className="max-w-screen-xxl lg:w-6/12 md:w-9/12 w-11/12 mx-auto my-10 md:flex" id={navanchor || ''}>
          {cards.map((item, i) =>
           <OverviewCardWithLink imgAlt={'alt'}
           cardTitle={item?.heading || ''} imgUrl={imageURLBuilder(item?.image).url()} linkList={item?.cardLinks || []}  key={i} className="w-full md:mx-3 md:my-0 my-3" />)}
        </div>
      )

      case "featured-card-collection":
       return (
         <Maxwidth className="my-16">
           <Text type="H4" className="font-normal text-3xl mb-10">{heading}</Text>
           <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-10" id={navanchor || ''}>{cards.map((item, i) =>
             <FeaturedCard imageUrl={imageURLBuilder(item?.image).url()} heading={item?.heading || ''} button={item?.button} link={item?.cardLinks || []} key={i} className="" />)}</div>
         </Maxwidth>
      )

      case "product-card-with-content-collection":
       return (
         <div className="max-w-screen-xxl lg:w-6/12 w-11/12 mx-auto my-10" id={navanchor || ''}>
           <Text type="H4" className="font-normal text-3xl mb-10">{heading}</Text>
           <div className={`grid gap-5 md:gap-2 lg:gap-5 md:grid-cols-2`}>{cards.map((item, i) =>
           <TitleWithContentCard subheading={item?.subheading}  imgUrl={imageURLBuilder(item?.image).url()} cardTitle={item?.heading || ''}
            cardContent={item?.body} imgAlt="alt" readMoreLink=""  key={i} className="md:mx-3 md:my-0 my-3" button={item.button} />)}
           </div>
         </div>
      )

      case "product-card-with-content-collection-3-cols":
        return (
          <div className="max-w-screen-xxl md:w-9/12 w-11/12 mx-auto my-10" id={navanchor || ''}>
            <Text type="H4" className="font-normal text-3xl mb-10">{heading}</Text>
            <div className={`grid gap-5 lg:grid-cols-3 md:grid-cols-2`}>{cards.map((item, i) =>
            <TitleWithContentCard subheading={item?.subheading}  imgUrl={imageURLBuilder(item?.image).url()} cardTitle={item?.heading || ''}
             cardContent={item?.body} imgAlt="alt" readMoreLink=""  key={i} className="md:mx-3 md:my-0 my-3" button={item.button} />)}
            </div>
          </div>
       )

      case "product-card-with-no-content-collection":
       return (
         <div className="max-w-screen-xxl lg:w-6/12 w-11/12 mx-auto my-10" id={navanchor || ''}>
           <Text type="H4" className="font-normal text-3xl mb-10">{heading}</Text>
           <div className="grid md:grid-cols-2 gap-5">{cards.map((item, i) =>
             <TitleOnlyContentCard imgUrl={imageURLBuilder(item?.image).url()} cardTitle={item?.heading || ''}
              imgAlt="alt" readMoreLink={item?.cardLinks?.[0]?.pageLink?.slug?.current || item?.button?.buttonLink?.slug?.current || ''}
               key={i} className="w-full" button={item.button} />)}
           </div>
         </div>
      )

      case "product-card-with-no-content-collection-3-cols":
        return (
          <div className="max-w-screen-xxl md:w-9/12 w-11/12 mx-auto my-10" id={navanchor || ''}>
            <Text type="H4" className="font-normal text-3xl mb-10">{heading}</Text>
            <div className="grid md:grid-cols-3 gap-5">{cards.map((item, i) =>
              <TitleOnlyContentCard imgUrl={imageURLBuilder(item?.image).url()} cardTitle={item?.heading || ''}
               imgAlt="alt" readMoreLink={item?.cardLinks?.[0]?.pageLink?.slug?.current || item?.button?.buttonLink?.slug?.current || ''}
                key={i} className="w-full" button={item.button} />)}
            </div>
          </div>
       )

      case "team-card-collection":
        return (
          <div className="max-w-screen-xxl lg:w-6/12 w-11/12 mx-auto my-10" id={navanchor || ''}>
            <Text type="H4" className="font-normal text-3xl mb-10">{heading}</Text>
            <div className="grid md:grid-cols-2 gap-5">{cards?.map((item, i) =>
              <TitleWithContentCard cardTitleClassName="text-viking" subheading={item?.subheading}  imgUrl={imageURLBuilder(item?.image).url()} cardTitle={item?.heading || ''}
              cardContent={''} imgAlt="alt" readMoreLink={item?.cardLinks?.[0]?.pageLink?.slug?.current || item?.button?.buttonLink?.slug?.current || ''}
              key={i} className="md:mx-3 md:my-0 my-3" button={item.button} />)}
            </div>
          </div>
        )

      case "team-card-collection-3-cols":
        return (
          <div className="max-w-screen-xxl md:w-9/12 w-11/12 mx-auto my-10" id={navanchor || ''}>
            <Text type="H4" className="font-normal text-3xl mb-10">{heading}</Text>
            <div className="grid md:grid-cols-3 gap-5">{cards?.map((item, i) =>
              <TitleWithContentCard cardTitleClassName="text-viking" subheading={item?.subheading}  imgUrl={imageURLBuilder(item?.image).url()} cardTitle={item?.heading || ''}
              cardContent={''} imgAlt="alt" readMoreLink={item?.cardLinks?.[0]?.pageLink?.slug?.current || item?.button?.buttonLink?.slug?.current || ''}
              key={i} className="md:mx-3 md:my-0 my-3" button={item.button} />)}
            </div>
          </div>
        )

      case "team-card-collection-4-cols":
        return (
          <Maxwidth className="my-16" id={navanchor || ''}>
            <Text type="H4" className="font-normal text-3xl mb-10">{heading}</Text>
            <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 gap-y-5 gap-x-1">{cards?.map((item, i) =>
              <TitleWithContentCard cardTitleClassName="text-viking" subheading={item?.subheading}  imgUrl={imageURLBuilder(item?.image).url()} cardTitle={item?.heading || ''}
              cardContent={''} imgAlt="alt" readMoreLink={item?.cardLinks?.[0]?.pageLink?.slug?.current || item?.button?.buttonLink?.slug?.current || ''}
              key={i} className="md:mx-3 md:my-0 my-3" button={item.button} />)}
            </div>
          </Maxwidth>
        )

      case "group-and-partners-card-collection":
       return (
         <div className="max-w-screen-xxl lg:w-6/12 w-11/12 mx-auto my-10" id={navanchor || ''}>
           <Text type="H4" className="font-normal text-3xl mb-10">{heading}</Text>
           <div className="grid md:grid-cols-2 gap-5">{cards.map((item, i) =>
             <GroupCard imgUrl={item?.image ? imageURLBuilder(item?.image).url() : ''} cardTitle={item?.heading || ''}
               cardContent={item?.body} imgAlt="alt" readMoreLink="" key={i} className="w-full md:my-0 my-3" button={item.button} />)}
           </div>
         </div>
      )

      case "customer-logos":
       return (
         <div className="max-w-screen-xxl lg:w-6/12 md:w-9/12 w-11/12 mx-auto my-10" id={navanchor || ''}>
           <div className="grid md:grid-cols-3 gap-5">{cards.map((item, i) =>
             <LogoOnlyContentCard imgUrl={item?.image ? imageURLBuilder(item?.image).url() : ''}
               imgAlt="alt" key={i} className="w-1/2 mx-auto md:w-full"/>)}
           </div>
         </div>
      )

      default:
       return <>Component {cardCollectionStyle} not available</>
    }
}

export default CardCollectionStyle;
