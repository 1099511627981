import React from 'react';
import './Toast.css';
import SuccessToastIcon from './SuccessToastIcon';
import FailureToastIcon from './FailureToastIcon';
import CancelToastIcon from './CancelToastIcon';
import { useToastState, useToastFuncs } from '../../../Context/ToastContext';

const Toast = ({ doubleType }: { doubleType?: any }) => {
  const { error, message, title, show } = useToastState();
  const { close } = useToastFuncs() as any;
  return (
    <div
      className={`toast${
        error ? ' error-toast' : ''
      } ${show ? ' show-toast' : ''}`}
    >
      {!error ? <SuccessToastIcon /> : <FailureToastIcon />}
      {doubleType ? (
        <div className='info-wrap'>
          <h4>{title}</h4>
          <p>{message}</p>
        </div>
      ) : (
        <p>{message}</p>
      )}
      <CancelToastIcon onClick={() => close()} />
    </div>
  );
};

export default Toast;
