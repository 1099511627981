import React from 'react'

import BusinessProcessReEngineering from '../../atoms/svg/BusinessProcessRe-engineering'
import ProfessionalService from '../../atoms/svg/ProfessionalServices'
import IPlatformCombined from '../../atoms/svg/IPlatformCombined'
import IPlatformCheckmark from '../../atoms/svg/IPlatformCheckmark'
import IPlatformCategory from '../../atoms/svg/IPlatformCategory'
import IPlatformSolution from '../../atoms/svg/IPlatformSolution'
import ServiceAndSupport from '../../atoms/svg/ServiceAndSupport'
import PortableTextRender from '../../molecules/PortableTextRender';

import { CardWideType } from '../../../types'

interface IProp {
  portableText?: any
  iconHint?: CardWideType['iconHint']
  navanchor?: string | null
}

const BlockContentWithIcon: React.FC<IProp> = ({ portableText, iconHint, navanchor }) => {

  const renderIcon = () => {
    switch(iconHint) {
      case 'Business-Process -Re-engineering.svg':
        return <BusinessProcessReEngineering />

      case 'Professional-Services.svg':
        return <ProfessionalService />

      case 'Servicing&Support-(SLA).svg':
        return <ServiceAndSupport />

      case 'i-platform-combine.svg':
        return <IPlatformCombined />

      case 'i-platform-category.svg':
        return <IPlatformCategory />

      case 'i-platform-checkmark.svg':
        return <IPlatformCheckmark />

      case 'i-platform-solution.svg':
        return <IPlatformSolution />

      default:
        return `${iconHint} isn't available`
    }
  }

  return (
    <section className={`max-w-screen-xxl lg:w-6/12 md:w-7/12 w-11/12 mx-auto ${navanchor ? 'pt-10' : ''}`} id={navanchor || ''}>
      {portableText ? (
        <section className={`border-b border-dove-grey border-opacity-20 ${navanchor ? 'pt-0' : ''}`}>
          <div className='lg:flex items-start my-8'>
            <div className='mb-5 lg:mb-0 mr-8'>{iconHint ? renderIcon() : ''}</div>
            <PortableTextRender className="text-lg"content={portableText} />
          </div>
        </section>
      ) : ''}
    </section>
  )
}

export default BlockContentWithIcon;
