import React from 'react'
import Text from '../../atoms/Text'
import Link from '../../atoms/Link'
import { CardWideType } from '../../../types'
import ContactUS from '../../atoms/svg/ContactUs'
import PortableText from '../../atoms/PortableText'
import Maxwidth from '../Maxwidth'
import { replaceLinks } from '../../../utils/service'
interface IProp {
  button: CardWideType['button']
  body: CardWideType['body']
  iconHint: CardWideType['iconHint']
}

const Contact: React.FC<IProp> = (prop) => {

  const renderIcon = () => {
    switch (prop.iconHint) {
      case 'mobi-contact':
        return <ContactUS />

      default:
        return <>{prop.iconHint} is not available</>
    }
  }

  return (
    <Maxwidth className="shadow-fullSides flex lg:flex-row flex-col justify-between items-center px-10 h-52 lg:h-40 py-5 my-10">
      <div>{prop.iconHint ? renderIcon() : ''}</div>
      {prop.body ? <PortableText className="text-lg font-semibold" content={prop?.body} template={{
        normal: (props: any) => <Text type="P" ptype="p-base" className="mb-0 lg:text-3xl text-xl font-light text-charcoal">{props?.children}</Text>,
      }} /> : ''}
      <Link to={replaceLinks(`/${prop?.button?.buttonLink?.slug?.current}`)} className="py-1 px-6 rounded-full text-white bg-gradient-to-r from-blue-stone to-viking">{prop?.button?.buttonText}</Link>
    </Maxwidth>
  )
}
  

export default Contact;
