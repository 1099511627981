import React from 'react';
import PortableTextRender from '../../molecules/PortableTextRender';
import Image from '../../atoms/Image'
import { imageURLBuilder } from '../../../utils'
interface IProp {
  portableText: any,
  navanchor?: string | null
  image?: any
  imagePlacement?: 'left' | 'right' | 'top' | 'above'
}

const SimpleContentBlock: React.FC<IProp> = ({ portableText, navanchor, image, imagePlacement }) => {
  return (
    <>
      {portableText ? (
        <section 
        className={`max-w-screen-xxl lg:w-6/12 md:w-7/12 w-11/12 mx-auto mb-8
        ${imagePlacement === 'left' || imagePlacement === 'right' ? 'md:flex items-start' : ''} 
        ${navanchor ? 'pt-8': ''}`} id={navanchor || ''}>
          {image && (imagePlacement === 'left' || imagePlacement === 'top' || imagePlacement === 'above') ?
            <Image src={imageURLBuilder(image).url()} alt="image" className={`${imagePlacement === 'left' ? 'mr-5' : ''} mb-5`} />
          :''}
          <PortableTextRender className="" content={portableText}  />
      </section>
      ) : image ? (
        <section className={`mb-5 max-w-screen-xxl lg:w-6/12 md:w-7/12 w-11/12 mx-auto ${navanchor ? 'pt-10' : ''}`} id={ navanchor || ''}>
          <Image src={imageURLBuilder(image).url()} alt="image"/>
        </section>
      ) : ''}
    </>
  )
}

export default SimpleContentBlock;
