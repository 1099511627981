import React from 'react'

export default function IPlatformCheckmark() {
  return (
    <svg width="54px" height="50px" viewBox="0 0 54 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>icon/checkmark</title>
      <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Our-Products-|-Our-Ecosystem" transform="translate(-351.000000, -2482.000000)" fillRule="nonzero">
          <g id="icon/checkmark" transform="translate(352.000000, 2483.000000)">
            <path d="M24.0001998,48 C10.7674304,48 0,37.232969 0,24.0001997 C0,10.7674304 10.7674304,0 24.0001998,0 C28.6016862,0 33.0785275,1.31117196 36.9469242,3.79408903 C37.7063812,4.28308186 37.9289049,5.29542482 37.4399121,6.0568794 C36.9493212,6.81833387 35.9389758,7.03846057 35.1775212,6.54986722 C31.8392662,4.40493054 27.9732665,3.2727363 24.0001998,3.2727363 C12.5715903,3.2727363 3.27273635,12.5715903 3.27273635,24.0001997 C3.27273635,35.4284097 12.5715903,44.7272636 24.0001998,44.7272636 C35.4284097,44.7272636 44.7272636,35.4284097 44.7272636,24.0001997 C44.7272636,23.317048 44.6945043,22.6406877 44.6313827,21.9755137 C44.5442908,21.0742328 45.2030728,20.2756244 46.1019568,20.1885326 C47.0052352,20.1054357 47.8014465,20.7602227 47.8889379,21.6591066 C47.9628461,22.4293502 48,23.2103804 48,24.0001997 C48,37.232969 37.2329691,48 24.0001998,48 Z" id="Path" stroke="#FFFFFF" fill="#404040"></path>
            <path d="M26.7272137,29.4546271 C26.3085335,29.4546271 25.8894539,29.2952251 25.5710493,28.9748229 L15.7528402,19.1566138 C15.1136339,18.517008 15.1136339,17.4806948 15.7528402,16.8414885 C16.3920466,16.2022822 17.4283598,16.2022822 18.0675661,16.8414885 L26.7296107,25.5035331 L49.5708495,2.66189479 C50.2104553,2.02268847 51.2467686,2.02268847 51.8859749,2.66189479 C52.5251812,3.30110111 52.5251812,4.3374144 51.8859749,4.97662072 L27.8857751,28.9768205 C27.5649735,29.2952251 27.1462934,29.4546271 26.7272137,29.4546271 L26.7272137,29.4546271 Z" id="Path" fill="#4CC2B8"></path>
          </g>
        </g>
      </g>
    </svg>
  )
}
