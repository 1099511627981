import React from 'react'
import { useIPlatformContext } from '../../../Context/IPlatformContext';
import Link from '../../atoms/Link';
import Text from '../../atoms/Text';
import Container, { CategoryContainerProps } from './index';


const RecentlyAdded = ({ view }: CategoryContainerProps) => {
  const { getRecentlyAddedApp } = useIPlatformContext();
  const recentlyAdded = getRecentlyAddedApp && getRecentlyAddedApp();

  const Header = () => <div className="flex w-full justify-between items-center"><Text type="H2" className="my-0">Recently-added Apps</Text> <Link to="/products/iplatform/search" ><Text type="H4" className="my-0">SEE ALL</Text></Link></div>
  return (
      <Container className="py-8" Header={Header} records={recentlyAdded} view={view}  grid_3={true} />
  )
}

export default RecentlyAdded;
