import * as React from "react"
import LoginHeader from "../LoginHeader"
import ChipsContainer from "../ChipsContainer"

const Iplatform = () => {
  return (
    <div>
      <LoginHeader />
      <ChipsContainer showFilter={true} />
    </div>
  )
}

export default Iplatform
