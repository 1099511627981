import React from 'react'

export default function Location() {
  return (
    <svg width="23px" height="34px" viewBox="0 0 23 34" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>1283B546-8F4D-4D0A-BA6A-FEB71E094197</title>
      <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Contact" transform="translate(-830.000000, -1076.000000)">
          <g id="Group-6" transform="translate(830.000000, 1076.000000)" fill="#4CC2B8" fillRule="nonzero">
            <g id="icon/map/teal">
              <path d="M11.0567998,2.55002274 C10.5867624,2.54662283 10.2034227,2.9248627 10.2000228,3.39405013 C10.1966229,3.86323757 10.5740128,4.2465773 11.0440502,4.24997721 C11.5132376,4.25337712 11.8965773,3.87598722 11.8999772,3.40679979 C11.9033771,2.93761236 11.5259872,2.55427263 11.0567998,2.55002274 Z" id="Path"></path>
              <path d="M11.510062,6.80011981 C8.9314364,6.78182075 6.81963614,8.86210231 6.800135,11.4399311 C6.78070064,14.0176263 8.86211384,16.130432 11.439938,16.1498664 C11.4518925,16.15 11.4637802,16.15 11.4757346,16.15 C14.0371298,16.15 16.1304974,14.0759294 16.149865,11.5100552 C16.1692994,8.93249355 14.0879529,6.8195542 11.510062,6.80011981 Z M11.4756011,14.8143696 C11.4671862,14.8143696 11.4583706,14.8143696 11.4499557,14.814236 C9.60863366,14.8003448 8.12187178,13.2911406 8.13576301,11.4499488 C8.14958745,9.61703835 9.6447642,8.13561662 11.4743989,8.13561662 C11.4828138,8.13561662 11.4916294,8.13561662 11.5000443,8.13575019 C13.3413663,8.14964144 14.8281282,9.65884567 14.814237,11.5000374 C14.8003458,13.3329479 13.3052358,14.8143696 11.4756011,14.8143696 Z" id="Shape"></path>
              <path d="M14.509988,3.43667129 C14.155244,3.31948127 13.7652439,3.49247304 13.6393582,3.82296667 C13.5135407,4.15346031 13.6993687,4.5162669 14.0543173,4.63345692 C17.0580483,5.62449344 19.0598765,8.28767792 19.0358723,11.2604066 C19.0330764,11.6109609 19.3360613,11.8973337 19.7126955,11.9 C19.7144004,11.9 19.7161734,11.9 19.7179464,11.9 C20.0920575,11.9 20.3968836,11.6188963 20.3997478,11.2699291 C20.4281164,7.75632339 18.0611787,4.60838105 14.509988,3.43667129 Z" id="Path"></path>
              <path d="M15.6165482,24.9981504 C20.1060893,19.3127682 22.9118078,16.3480554 22.9496746,11.3739519 C22.9971936,5.11561546 17.834758,0 11.4739505,0 C5.1873915,0 0.0481754396,5.00890041 0.000386359646,11.2046156 C-0.0382228971,16.3135905 2.81960459,19.2742525 7.34012756,24.9972207 C2.84302664,25.6583626 0.000386359646,27.319651 0.000386359646,29.3516198 C0.000386359646,30.7127514 1.27921674,31.9341639 3.60137454,32.7908062 C5.71496135,33.5705499 8.51123002,34 11.4750305,34 C14.4388309,34 17.2350996,33.5705499 19.3486864,32.7908062 C21.6708442,31.9340975 22.9496746,30.712685 22.9496746,29.3515534 C22.9496746,27.3207135 20.1096667,25.6598236 15.6165482,24.9981504 Z M1.35029287,11.214643 C1.39241206,5.74747216 5.92663727,1.32812759 11.4740855,1.32812759 C17.0872099,1.32812759 21.6416172,5.84296454 21.599768,11.3640574 C21.5638587,16.0878088 18.588381,18.9988652 13.838025,25.0911857 C12.9907138,26.1773285 12.2125888,27.2042367 11.4760429,28.208766 C10.7416571,27.2036391 9.97905676,26.1951254 9.11905581,25.0907873 C4.17234604,18.7433999 1.31370858,16.0523478 1.35029287,11.214643 Z M11.4750305,32.6719388 C5.68019952,32.6719388 1.35029287,30.9190096 1.35029287,29.3516198 C1.35029287,28.1892426 3.93873304,26.7208647 8.29091426,26.212391 C9.25297324,27.4542567 10.096437,28.5825011 10.9237011,29.7347846 C11.0500586,29.9107615 11.2553897,30.0155508 11.474423,30.0156836 C11.4746255,30.0156836 11.474828,30.0156836 11.4750305,30.0156836 C11.6938612,30.0156836 11.8991248,29.9112928 12.0256849,29.7356479 C12.8451191,28.5983723 13.7116674,27.4423036 14.6654915,26.2131215 C19.0136228,26.7221264 21.599768,28.1900394 21.599768,29.3516862 C21.599768,30.9190096 17.2698614,32.6719388 11.4750305,32.6719388 Z" id="Shape"></path>
            </g>
          </g>
          <rect id="Rectangle-Copy-45" stroke="#979797" x="0" y="0" width="1440" height="3255"></rect>
        </g>
      </g>
    </svg>
  )
}
