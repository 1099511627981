import React, { useState, useEffect, useMemo } from 'react'

import Text from '../../atoms/Text'
import Next from '../../atoms/svg/Next'
import SearchGrey from '../../atoms/svg/SearchGrey'
import Link from '../../atoms/Link'
import { IProducts } from '../../../types/components'

import { paginate } from '../../../utils/service'



type currentData = {
  page?: number;
  per_page?: number;
  pre_page?: number | null;
  next_page?: number | null;
  total?: number;
  total_pages?: number;
  data?: IProducts[];
  index?: string

}

interface IProductTableList {
  productList: IProducts[]
};

const sortProductsAlphabetically = (products: IProducts[]) => {
  const result = products?.sort?.((a, b) => {
    if(a?.product?.provider?.name?.toLowerCase?.() && b?.product?.provider?.name?.toLowerCase?.()) {
      if ( a?.product?.provider?.name?.toLowerCase?.() < b?.product?.provider?.name?.toLowerCase?.()) {
        return -1
      }
      if (a?.product?.provider?.name?.toLowerCase?.() > b?.product?.provider?.name?.toLowerCase?.()) {
        return 1
      }
    }
    return 0
  });
  return result || products;
}


const ProductTableList: React.FC<IProductTableList> = ({ productList }) => {
  const [page, setPage] = useState(1)
  const [perPage] = useState(25)
  const [products, setProducts] = useState(sortProductsAlphabetically(productList))
  const [currentData, setCurrentData] = useState<currentData>({})

  const handlePageChange = (_page: number | null | undefined) => {
    if (_page) {
      setPage(_page)
    }
  }

  useEffect(() => {
    const _paginated = paginate(products, page, perPage)
    setCurrentData(_paginated)
  }, [page, products])

  useEffect(() => {
    setProducts(productList)
  }, [productList])

  const handleSearch = (searchValue: string) => {
    const _searchValue = searchValue.trim()
    setPage(1)  
    if (_searchValue) {
      const searchList = productList?.filter((product) => product?.product.title?.toLowerCase()?.includes(_searchValue?.toLowerCase()) || product?.product?.provider?.name?.toLowerCase()?.includes(_searchValue?.toLowerCase()))
      setProducts([...searchList])
    } else {
      setProducts([...productList])
    }
  }
  

  return (
    <section className={`border border-dark-grey border-opacity-20`}>
      <div className="flex justify-between flex-wrap items-center px-6 py-1">
        <Text type="H4" className="text-charcoal font-medium mb-0">C360 Insurance Product List</Text>
        <div className="p-2 border-b-2 border-blue-stone flex items-center lg:w-5/12 w-full">
            <div className="mr-2"><SearchGrey fill="#12505F" /></div>
          <input type="search" className="border-0 placeholder-jumbo text-xs flex-1 focus:outline-none focus:border-0 truncate" placeholder="Search Insurer name or Product name" onChange={(e) => handleSearch(e.target.value)} />
        </div>
      </div>
      <table className="mt-3 w-full text-sm table-fixed hidden lg:table">
        <thead className="bg-jumbo bg-opacity-20 uppercase text-blue-stone">
          <tr className="px-2 py-3 text-left">
            <th className="w-5/12 py-3 px-2">Product Provider</th>
            <th className="w-4/12">Product name</th>
            <th className="text-transparent w-3/12">Actions</th>
          </tr>
        </thead>
        <tbody className="text-dove-grey text-sm px-2 overflow-x-auto">
          {currentData?.data?.length ?
            currentData?.data?.map((product, index) => (
              <tr className="border-b border-jumbo border-opacity-35" key={index}>
                <td className="w-5/12 py-4 px-2 truncate" title={product.product.provider?.name}>{product.product.provider?.name}</td>
                <td className="w-4/12 truncate" title={product?.product?.title}>{product?.product?.title}</td>
                <td className="cursor-pointer w-3/12 px-2">
                  <Link to="/contact/#demo" className="text-blue-stone rounded-full py-2 px-6 xl:px-3 xl:py-1 2xl:px-6 2xl:py-2 border border-blue-stone transition-colors duration-700 hover:bg-blue-stone hover:text-white text-center w-full truncate block lg:text-xs xl:text-sm">Apply for Agency</Link>
                </td>
              </tr>
            ))
            : <tr className="border-b border-jumbo border-opacity-35">
                <td colSpan={3} className="text-center py-4">No product available at the moment</td>
            </tr>}
        </tbody>
        <tfoot>
          <tr className="w-full justify-end bg-jumbo bg-opacity-20 px-5 py-3 text-blue-stone">
              <td>
              </td>
              <td>
              </td>
              <td colSpan={100} className="flex items-center cursor-pointer px-2 py-3 text-xs w-full justify-around">
                <p className="mr-3">{currentData.index}</p>
                <div className="transform rotate-180 mr-5" onClick={() => handlePageChange(currentData?.pre_page)}>
                <Next fill={currentData?.pre_page ? "#12505F" : '#B2B2B2'} width="12px" />
                </div>
                <div onClick={() => handlePageChange(currentData?.next_page)}>
                  <Next fill={currentData?.next_page ? "#12505F" : '#B2B2B2'} width="12px" />
                </div>
              </td>
            </tr>
        </tfoot>
      </table>
      <section className="lg:hidden mt-3">
        {currentData?.data?.length ?
          currentData?.data?.map((product, index) => (
            <div className="border border-dark-grey border-opacity-20" key={index}>
              <div className="grid grid-cols-2">
                <Text type="P" ptype="sm-bold" className="text-xs bg-jumbo bg-opacity-20 uppercase text-blue-stone p-3">Product Provider:</Text>
                <Text type="P" ptype="sm-light" className="text-xs p-3 text-dove-grey">{product.product?.provider?.name}</Text>
              </div>
              <div className="grid grid-cols-2">
                <Text type="P" ptype="sm-bold" className="text-xs bg-jumbo bg-opacity-20 uppercase text-blue-stone p-3">Product name:</Text>
                <Text type="P" ptype="sm-light" className="text-xs p-3 text-dove-grey">{product.product.title}</Text>
              </div>
              <div className="grid grid-cols-2">
                <Text type="P" ptype="sm-bold" className="text-xs bg-jumbo bg-opacity-20 uppercase text-blue-stone p-3"><span className="opacity-0">Action:</span></Text>
                <Text type="P" ptype="sm-light" className="text-xs mb-2 p-3">
                  <Link to="/contact/#demo" className="text-blue-stone rounded-full py-1 px-4 border border-blue-stone text-center block">Apply for Agency</Link>
                </Text>
              </div>
            </div>
          )) : 'No product available at the moment'}
          <div className="flex items-center cursor-pointer px-2 py-3 justify-end text-dove-grey">
            <Text type="P" ptype="sm-light" className="mr-3 text-xs">{currentData.index}</Text>
            <div className="transform rotate-180 mr-5" onClick={() => handlePageChange(currentData?.pre_page)}>
              <Next fill={currentData?.pre_page ? "#12505F" : '#B2B2B2'} />
            </div>
            <div onClick={() => handlePageChange(currentData?.next_page)}>
              <Next fill={currentData?.next_page ? "#12505F" : '#B2B2B2'} />
            </div>
          </div>
      </section>
    </section>
  )
}

export default ProductTableList