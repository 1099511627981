import React from 'react'
import Text from '../../atoms/Text'

import Maxwidth from '../../molecules/Maxwidth'
import PortableText from '../../atoms/PortableText'

import { IHero } from '../../../types/components'


const HeroSection: React.FC<IHero> = ({ children, portableText = null, className='', imageUrl, heroSize, ...rest }) => {

  return (
    <section className={`mb-8 w-full ${heroSize === 'short' ? 'xl:h-mid': 'xl:h-large'}  h-96 overflow-hidden bg-center bg-cover bg-no-repeat  ${className}`} style={{ 'backgroundImage': `url(${imageUrl})` }} {...rest}>
      <div className="h-full flex items-end py-5 bg-gradient-to-t from-black via-transparent to-transparent">
        <Maxwidth>
          {portableText ? <PortableText className="text-lg font-semibold" content={portableText} template={{
              normal: (props: any) => <Text type="H3" className="text-white text-4xl lg:w-4/12 font-light leading-normal">{props.children}</Text>,
            h2: (props: any) => <Text type="H2" className="text-white text-4xl lg:w-4/12 leading-normal font-light">{props.children}</Text>,
            h1: (props: any) => <Text type="H1" className="text-white text-4xl lg:w-4/12 leading-normal font-light">{props.children}</Text>,
              strong: (props: any) => <strong className="text-white text-4xl lg:w-4/12 leading-normal font-medium">{props.children}</strong>,
            }} />: ''}
        </Maxwidth>
      </div>
    </section>
  )
}

export default HeroSection
