import React from 'react'

export default function Demo() {
  return (
    <svg width="70px" height="60px" viewBox="0 0 70 60" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>icon/demo</title>
      <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Our-Products-|-Our-Core-Insurance-Suite--|-C360-Screen-2" transform="translate(-355.000000, -1594.000000)" fillRule="nonzero">
          <g id="icon/demo" transform="translate(355.000000, 1594.000000)">
            <path d="M22.5181235,26.6130488 C23.4737772,25.6605554 24,24.3781377 24,23.0017221 C24,20.2436985 21.8420465,18 19.1893342,18 L16,18 L16,28 L19.1804016,28 C20.4305641,28.0023791 21.6161987,27.5121526 22.5181235,26.6130488 Z M19.1841347,26.0061904 L18.0001,26.0041933 L18.0001,19.9972538 L19.1894675,19.9972538 C20.7392051,19.9972538 22.0000333,21.345044 22.0000333,23.0017221 C22.0000333,24.5930279 20.7572036,26.0061904 19.1841347,26.0061904 L19.1841347,26.0061904 Z" id="Shape" fill="#4CC2B8"></path>
            <polygon id="Path" fill="#4CC2B8" points="44 28 44 18 42.0461286 18 39.0463018 21.3697259 36.0371481 18 34 18 34 28 35.9987742 28 35.9987742 20.9569814 39.048567 24.3725086 42.0012258 21.0556406 42.0012258 28"></polygon>
            <path d="M54,24.0053523 L54,21.9946477 C54,19.7919529 52.2056461,18 50,18 C47.7943539,18 46,19.7919529 46,21.9946477 L46,24.0053523 C46,26.2080471 47.7943539,28 50,28 C52.2056461,28 54,26.2080471 54,24.0053523 L54,24.0053523 Z M47.9999333,24.0053523 L47.9999333,21.9946477 C47.9999333,20.8933003 48.8970436,19.9973904 50,19.9973904 C51.1029564,19.9973904 52.0000667,20.8933003 52.0000667,21.9946477 L52.0000667,24.0053523 C52.0000667,25.1066997 51.1029564,26.0027427 50,26.0027427 C48.8970436,26.0027427 47.9999333,25.1066997 47.9999333,24.0053523 Z" id="Shape" fill="#4CC2B8"></path>
            <polygon id="Path" fill="#4CC2B8" points="32 19.9972839 32 18 26 18 26 28 32 28 32 25.9808806 27.9994668 25.9808806 27.9994668 23.9877242 30.0003999 23.9877242 30.0003999 21.9904403 27.9994668 21.9904403 27.9994668 19.9972839"></polygon>
            <path d="M65.8391016,0 L53.457168,0 L53.457168,2.06932701 L65.8391016,2.06932701 C67.0025781,2.06932701 67.9489453,3.02426307 67.9489453,4.19810475 L67.9489453,6.19901513 L2.05105469,6.19901513 L2.05105469,4.19810475 C2.05105469,3.02426307 2.99755859,2.06932701 4.16089844,2.06932701 L51.3927148,2.06932701 L51.3927148,0 L4.16089844,0 C1.86662109,0 0,1.88325034 0,4.19810475 L0,30.004414 L2.05105469,30.004414 L2.05105469,8.26834214 L67.949082,8.26834214 L67.949082,37.2997503 L2.05105469,37.2997503 L2.05105469,32.073603 L0,32.073603 L0,43.3853815 C0,45.7000979 1.86662109,47.5833483 4.16089844,47.5833483 L30.89625,47.5833483 L30.89625,53.7921569 L24.7428125,53.7921569 L24.7428125,60 L31.9216406,60 L31.9216406,57.930673 L26.7937305,57.930673 L26.7937305,55.861346 L43.2059961,55.861346 L43.2059961,57.930673 L38.0748047,57.930673 L38.0748047,60 L45.2571875,60 L45.2571875,53.7921569 L39.1001953,53.7921569 L39.1001953,47.5833483 L65.8391016,47.5833483 C68.1333789,47.5833483 70,45.7000979 70,43.3853815 L70,4.19810475 C70,1.88325034 68.1333789,0 65.8391016,0 Z M37.0492773,53.7921569 L32.9473047,53.7921569 L32.9473047,47.5833483 L37.0492773,47.5833483 L37.0492773,53.7921569 Z M65.8391016,45.5141592 L4.16089844,45.5141592 C2.99742187,45.5141592 2.05105469,44.5592231 2.05105469,43.3853815 L2.05105469,39.3690773 L67.949082,39.3690773 L67.949082,43.3853815 C67.949082,44.5590852 67.0025781,45.5141592 65.8391016,45.5141592 Z" id="Shape" fill="#404040"></path>
            <polygon id="Path" fill="#404040" points="34 41 36 41 36 43 34 43"></polygon>
            <polygon id="Path" fill="#404040" points="38 41 40 41 40 43 38 43"></polygon>
            <polygon id="Path" fill="#404040" points="30 41 32 41 32 43 30 43"></polygon>
            <polygon id="Path" fill="#404040" points="4 10 6 10 6 12 4 12"></polygon>
            <polygon id="Path" fill="#4CC2B8" points="8 10 10 10 10 12 8 12"></polygon>
            <polygon id="Path" fill="#FFFFFF" points="34 58 36 58 36 60 34 60"></polygon>
          </g>
        </g>
      </g>
    </svg>
  )
}
