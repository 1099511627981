import React from 'react';

const ListGreyIcon: React.FC = () => {

  return (
    <svg width="32px" height="38px" viewBox="0 0 32 38" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>icon/list-grey-icon</title>
      <g id="icon/list-grey-icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="copy" transform="translate(5.000000, 7.000000)"></g>
          <path d="M27.2941176,38 L4.70588235,38 C2.112,38 0,35.8701 0,33.25 L0,4.75 C0,2.1299 2.112,0 4.70588235,0 L27.2941176,0 C29.888,0 32,2.1299 32,4.75 L32,33.25 C32,35.8701 29.888,38 27.2941176,38 Z M4.70588235,1.9 C3.14917647,1.9 1.88235294,3.1787 1.88235294,4.75 L1.88235294,33.25 C1.88235294,34.8213 3.14917647,36.1 4.70588235,36.1 L27.2941176,36.1 C28.8508235,36.1 30.1176471,34.8213 30.1176471,33.25 L30.1176471,4.75 C30.1176471,3.1787 28.8508235,1.9 27.2941176,1.9 L4.70588235,1.9 Z" id="Shape" fill="#404040" fillRule="nonzero"></path>
          <path d="M25.7777778,23 L6.22222222,23 C5.54755556,23 5,22.552 5,22 C5,21.448 5.54755556,21 6.22222222,21 L25.7777778,21 C26.4524444,21 27,21.448 27,22 C27,22.552 26.4524444,23 25.7777778,23 Z" id="Path" fill="#4CC2B8" fillRule="nonzero"></path>
          <path d="M25.7777778,30 L6.22222222,30 C5.54755556,30 5,29.552 5,29 C5,28.448 5.54755556,28 6.22222222,28 L25.7777778,28 C26.4524444,28 27,28.448 27,29 C27,29.552 26.4524444,30 25.7777778,30 Z" id="Path" fill="#4CC2B8" fillRule="nonzero"></path>
          <path d="M25.7777778,16 L6.22222222,16 C5.54755556,16 5,15.552 5,15 C5,14.448 5.54755556,14 6.22222222,14 L25.7777778,14 C26.4524444,14 27,14.448 27,15 C27,15.552 26.4524444,16 25.7777778,16 Z" id="Path" fill="#4CC2B8" fillRule="nonzero"></path>
          <path d="M25.7777778,9 L6.22222222,9 C5.54755556,9 5,8.552 5,8 C5,7.448 5.54755556,7 6.22222222,7 L25.7777778,7 C26.4524444,7 27,7.448 27,8 C27,8.552 26.4524444,9 25.7777778,9 Z" id="Path" fill="#4CC2B8" fillRule="nonzero"></path>
      </g>
    </svg>
  )
}

export default ListGreyIcon;
