import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Text from '../../atoms/Text'
import FilterList from './FilterList'
import ProductTableList from './ProductList'

import { IProducts, TypesList, Root } from '../../../types/components';

const sortCategoriesAlphabetically = (categoryList: TypesList[]) => {
   if(!categoryList?.length) {
     return categoryList;
   }

   const sortedCategories = categoryList.sort((a, b) => {
      if(a.node.title?.toLowerCase?.() < b.node.title?.toLowerCase?.()) {
        return -1;
      }
      if(a.node.title?.toLowerCase?.() > b.node.title?.toLowerCase?.()) {
        return 1;
      }
      return 0;
    });

    return sortedCategories;
}

export const useC360ProductList = () => {
  const { products, types }: Root = useStaticQuery(
    graphql`
      query C360ProductList {
        products: allSanityC360Product {
          list: edges {
            product: node {
              _type
              _id
              title
              provider {
                name
                id
                _id
                _key
                _type
              }
              type {
                _key
                title
                type
                _id
              }
            }
          }
        }
        types: allSanityC360ProductType {
          list: edges {
            node {
              title
              id
              type
            }
          }
        }
      }
    `
  )
  return { products: products.list, types: types.list}
}


const ProductList: React.FC = () => {

  const { products, types } = useC360ProductList()

  const [categories, setCategories] = useState<TypesList[] | []>([])
  const [productList, setProductList] = useState<IProducts[] |  []>([])
  const [activeFilter, setActiveFilter] = useState<string[]>([])

  useEffect(() => {
    if (types.length) {
      setCategories(sortCategoriesAlphabetically(types))
    }
    if (products.length) {
      setProductList(products)
    }
    
  }, [products, types])

  useEffect(() => {
    if (activeFilter.length) {
      const _filteredList = products.filter((product) => activeFilter.includes(product.product.type?.type!))
     
      setProductList([..._filteredList])
    } else {
      setProductList([...products])
    }
  }, [activeFilter])
  

  const toggleFilter = (category: string) => {
    if (activeFilter?.includes(category)) {
      const _filterList = activeFilter.filter((list) => list !== category)   
         
      setActiveFilter(_filterList)
      
    } else {
      const _filterList = activeFilter
      _filterList?.push(category)
      setActiveFilter([..._filterList])
    }
  }

  const clearFilter = () => {
    setActiveFilter([])
  }
  

  return (
    <section className="max-w-screen-xxl xl:w-6/12 lg:w-8/12 md:w-7/12 w-11/12 mx-auto my-10">
      <Text type="P" ptype="p-base" className="font-light text-3xl text-charcoal">C360 Insurance product list</Text>
      <div className="flex flex-wrap items-center">
        {categories.length && categories.map((category, index) => (
          <FilterList key={index} isSelected={activeFilter?.includes(category.node.type)} onClick={() => toggleFilter(category.node.type)}>{category.node.title}</FilterList>
        ))}
        <Text type="P" ptype="p-base" className="uppercase text-right cursor-pointer text-blue-stone justify-self-end flex-1 mb-0" onClick={clearFilter}>Clear all</Text>
      </div>
      <div className="my-8">
        <ProductTableList productList={productList} />
      </div>
    </section>
  )
}

export default ProductList