import * as React from "react"
import LoginHeader from "../LoginHeader"
import Text from "../../atoms/Text"
import PlatformStoreList from "../PlatformStoreList"
import EnquiryForm from "../EnquiryForm";
import ListGreyIcon from "../../atoms/svg/ListGreyIcon";
import Link from "../../atoms/Link";

const IplatformEnquiry = () => {
  return (
    <div>
      <LoginHeader />
      <div className="">
          <div className="flex justify-center items-center">
            <ListGreyIcon />
            <Text type="H2" className="ml-4">My List</Text>
          </div>
          <PlatformStoreList />
          <EnquiryForm />
          <div className="mx-auto flex items-center justify-center w-full" ><Link to="/products/iplatform/search" ><button color="btn-primary" className="rounded-3xl py-2 px-2 my-12 w-80 mx-auto bg-blue-stone text-white">Go Back to iPlatform</button></Link></div>
      </div>
    </div>
  )
}

export default IplatformEnquiry
