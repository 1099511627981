import React from 'react'
import Text from '../../atoms/Text'
import Link from '../../atoms/Link'

import { CardWideType } from '../../../types'
import PortableText from '../../atoms/PortableText'
import { replaceLinks } from '../../../utils/service'

interface IProp {
  body: CardWideType['body']
  button: CardWideType['button']
  title?: CardWideType['title']
  imageUrl: any
  className?: string
  subtitle?: string
  readMoreLink?: string
}

const CaseStudy: React.FC<IProp> = ({ button, body, className = '', imageUrl, ...rest }) => {  
  return (
    <section className={`w-full lg:h-large h-96 overflow-hidden bg-center bg-cover bg-no-repeat  ${className}`} style={{ 'backgroundImage': `url(${imageUrl})` }} {...rest}>
      <div className="h-full lg:w-7/12 md:w-10/12 mx-auto">
        <div className="lg:w-4/12 md:w-5/12 w-10/12 mx-auto md:mx-0 bg-blue-stone text-white py-10 px-5 flex flex-col justify-between lg:h-5/6">
          {body ? <PortableText className="text-lg font-semibold" content={body} template={{
              normal: (props: any) => <Text type="P" ptype="p-base" className="text-3xl">{props.children}</Text>,
              h2: (props: any) => <Text type="H4" className="font-medium my-10">{props.children}</Text>,
            }} />: ''}
          <Link to={replaceLinks(button?.buttonLink?.slug?.current!) || '/'} className="w-full bg-viking block py-2 text-center rounded-full border border-viking hover:bg-transparent transition duration-700">{button?.buttonText}</Link>
        </div>
      </div>
    </section>
  )
}

export default CaseStudy