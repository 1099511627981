import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import Layout from '../../common/components/template/Layout';
import { PageProp } from '../../common/types';
import PagePartRenderer from '../PagePartRender'
import { useGlobalStateContext } from '../../common/Context/app';
import Toast from '../../common/components/atoms/Toast';

const Index: React.FC<PageProp> = ({ data, pageContext }) => {
  const pageParts = data?.pageData?.pageParts
  const { setSiteConfig } = useGlobalStateContext();
  // console.log({ pageContext })
  // console.log({ pageParts })

  useEffect(() => {
    setSiteConfig && pageContext && setSiteConfig({
      payload: pageContext,
      type: 'SET'
    })
  }, [pageContext]);

  return (
    <Layout 
    metaDescription={pageContext?.metadata?.page_description || ''} 
    metaTitle={pageContext?.metadata?.page_title || ''} 
    meta={[]} 
    lang={'en'}>
       <Toast /> 
      {pageParts && pageParts.map((part, i) => <PagePartRenderer part={ part } key={i} id={i}  />)}
    </Layout>
  )
}

export const query = graphql`
  query Page($id: String!) {
    pageData: sanityPage(_id: {eq: $id}) {
      pageParts {
        ... on SanityIPlatformEnquirePart {
          _key
          _type
          title
        }

        ... on SanityIPlatformLandingPart {
          _key
          _type
          title
        }
  
        ... on SanityIPlatformSearchPart {
          _key
          _type
          title
        }

        ... on SanityCardCollection {
          _key
          heading
          navanchor
          title
          cardCollectionStyle
          _type
          cards {
            _id
            _key
            button {
              buttonText
              buttonHref
              buttonLink {
                slug {
                  current
                }
              }
            }
            body: _rawBody(resolveReferences: {maxDepth: 1})
            image: _rawImage(resolveReferences: {maxDepth: 1})
            _type
            heading
            title
            subheading
            cardLinks {
              _key
              navanchor
              linkTitle
              linkURL
              _type
              pageLink {
                _type
                slug {
                  current
                  _key
                }
                title
              }
            }
          }
        }

        ... on SanityCardWide {
          _key
          _type
          body: _rawBody(resolveReferences: {maxDepth: 1})
          image: _rawImage(resolveReferences: {maxDepth: 3})
          button {
            _key
            buttonHref
            buttonText
            buttonLink {
              _key
              slug {
                _key
                current
              }
            }
          }
          cardStyle
          iconHint
          navanchor
          title
        }

        ... on SanityFormPart {
          _key
          _type
          navanchor
          title
          simpleForm {
            formId
            additionalContent: _rawAdditionalContent
          }
        }

        ... on SanityHero {
          _key
          _type
          body: _rawBody(resolveReferences: {maxDepth: 1})
          image: _rawImage(resolveReferences: {maxDepth: 1})
          navanchor
          title
          heroSize
        }

        ... on SanityReadNextPart {
          _key
          _type
          body: _rawBody(resolveReferences: {maxDepth: 1})
          navanchor
          title
          pages {
            _key
            _type
            linkTitle
            page {
              slug {
                _type
                current
              }
            }
            title
          }
        }

        ... on SanitySimpleContentBlock {
          _key
          _type
          body: _rawBody(resolveReferences: {maxDepth: 4})
          image: _rawImage(resolveReferences: {maxDepth: 10})
          button {
            _key
            buttonHref
            buttonText
            buttonLink {
              _key
              slug {
                _key
                current
              }
              title
            }
          }
          navanchor
          title
          image_placement
        }

        ... on SanitySimpleContentGrid {
          _key
          _type
          body: _rawBody(resolveReferences: {maxDepth: 10})
          button {
            _key
            buttonHref
            buttonText
            buttonLink {
              _key
              slug {
                _key
                current
              }
            }
          }
          navanchor
          title
          contentBlocks: _rawContentBlocks(resolveReferences: {maxDepth: 10})
        }

        ... on SanityTabContainerPart {
          _key
          _type
          title
          tabs {
            navanchor
            name
            _type
            _key
            title
            tabParts {
              ... on SanityC360ProductList {
                title
                _type
                _key
              }
              ... on SanityCardCollection {
                _key
                heading
                navanchor
                title
                cardCollectionStyle
                _type
                button {
                  buttonText
                  buttonHref
                  buttonLink {
                    slug {
                      current
                    }
                  }
                }
                cards {
                  _id
                  _key
                  body: _rawBody(resolveReferences: {maxDepth: 1})
                  image: _rawImage(resolveReferences: {maxDepth: 1})
                  _type
                  heading
                  title
                  subheading
                  cardLinks {
                    _key
                    linkTitle
                    linkURL
                    _type
                    pageLink {
                      _type
                      slug {
                        current
                        _key
                      }
                      title
                    }
                  }
                }
              }

              ... on SanityHero {
                _key
                _type
                body: _rawBody(resolveReferences: {maxDepth: 1})
                image: _rawImage(resolveReferences: {maxDepth: 1})
                navanchor
                title
              }

              ... on SanityReadNextPart {
                _key
                _type
                body: _rawBody(resolveReferences: {maxDepth: 1})
                navanchor
                title
                pages {
                  _key
                  _type
                  linkTitle
                  page {
                    slug {
                      _type
                      current
                    }
                  }
                  title
                }
              }

              ... on SanitySimpleContentBlock {
                _key
                _type
                body: _rawBody(resolveReferences: {maxDepth: 1})
                image: _rawImage(resolveReferences: {maxDepth: 10})
                button {
                  _key
                  buttonHref
                  buttonText
                  buttonLink {
                    _key
                    slug {
                      _key
                      current
                    }
                    title
                  }
                }
                navanchor
                title
                image_placement
              }

              ... on SanityCardWide {
                _key
                _type
                body: _rawBody(resolveReferences: {maxDepth: 1})
                image: _rawImage(resolveReferences: {maxDepth: 3})
                button {
                  _key
                  buttonHref
                  buttonText
                  buttonLink {
                    _key
                    slug {
                      _key
                      current
                    }
                  }
                }
                cardStyle
                iconHint
                navanchor
                title
              }

              ... on SanityYoutube {
                _key
                _type
                autoplay
                controls
                loop
                disablekb
                modestbranding
                navanchor
                title
                url
              }

              ... on SanityFormPart {
                _key
                _type
                navanchor
                title
                simpleForm {
                  formId
                  additionalContent: _rawAdditionalContent
                }
              }

              ... on SanitySimpleContentGrid {
                _key
                _type
                body: _rawBody(resolveReferences: {maxDepth: 10})
                button {
                  _key
                  buttonHref
                  buttonText
                  buttonLink {
                    _key
                    slug {
                      _key
                      current
                    }
                  }
                }
                navanchor
                title
                contentBlocks: _rawContentBlocks(resolveReferences: {maxDepth: 10})
              }
            }
          }
          _type
          _key
          title
        }

        ... on SanityYoutube {
          _key
          _type
          autoplay
          controls
          loop
          disablekb
          modestbranding
          navanchor
          title
          url
        }
      }
    }
  }
`

export default Index;
