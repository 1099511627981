import React, { useMemo } from 'react'
import { useIPlatformContext } from '../../../Context/IPlatformContext';
// import { IPlatformCardProps } from '../../molecules/Card/IPlatformCard';
import Container, { CategoryContainerProps } from './index';

type IProp = {
  grid_3?: boolean
} & CategoryContainerProps

const Categories = ({ view, CustomHeader, grid_3 }: IProp) => {
  const { getCategories, store} = useIPlatformContext();

  const allCategories = useMemo(() => getCategories?.() || [], [store])
  return (
    <Container Header={CustomHeader} records={allCategories} view={view} grid_3={grid_3} />
  )
}

export default Categories
