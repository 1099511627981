import React from 'react';
import SelectSearchComp from 'react-select';
import './input.css';

const getValue = (options: IOptions[], checkValue: string) => {
  if (!options || !checkValue) {
    return null;
  }
  const obj = options.filter((option) => option.value === checkValue);
  return obj;
};

export interface IOptions {
  value: string;
  label: string;
}

export interface IValues {
  value: string;
  name: string;
}

export interface InputProps {
  name: string;
  value: string;
  onChange: (e: { target: IValues }) => void;
  required?: boolean;
  type?: string;
  placeholder?: string;
  _className?: string;
  disabled?: boolean;
  bodyClassName?: string;
  options?: IOptions[];
  onFocus?: any;
  onBlur?: any;
}


const InputComp: React.FC<InputProps> = ({ _className, ...props }) => <input
  {...props}
  className={`w-full p-2 px-2 pt-6 border-b border-solid border-light-black focus:border-gray-500 border-b-1 outline-none bg-transparent placeholder-gray-400 ${_className || ''} input-comp`}
/>

const Select: React.FC<InputProps> = ({ _className, bodyClassName, options, value, onChange, ...props }) => {
  const handleChange = (e: any) => {
    onChange({ target: { value: e.value, name: props.name } });
  };
  return (
    <SelectSearchComp
      onChange={handleChange}
      options={options}
      {...props}
      placeholder=""
      className='basic-single py-2 pt-5 select-search input-comp'
      value={getValue(options || [], value)}
    />
  )
}

const Input: React.FC<InputProps> = (props) => {
  const [isFocused, setIsFocused] = React.useState(false);
  const handleFocus = (e: any) => {
    !isFocused && setIsFocused(true);
  }
  const handleBlur = (e: any) => {
    isFocused && setIsFocused(false);
  }
  return (<div className={`relative flex flex-col w-full max-w-lg ${props.bodyClassName || ""}`}>
    <label className={`absolute transition-all top-5 -z-10 py-0 pl-2  text-lg text-gray-400 ${(isFocused || props.value) ? 'focused' : ''}`} htmlFor={props.name}>{props.placeholder}</label>
    {(props.type === "select") ? <Select {...props} placeholder="" onFocus={handleFocus} onBlur={handleBlur} /> : <InputComp {...props} placeholder="" onFocus={handleFocus} onBlur={handleBlur} />}
    <span className={`mt-1 text-gray-400 italic text-right mr-4 text-xxs ${!props.required ? "invisible" : ""}`}>Required</span>
  </div>)
}

export default Input;