import React from 'react';
import Text from '../../atoms/Text'
import { CoreModulesCard } from '../../molecules/Card';
import { SimpleContentGridT } from '../../../types';
import PortableText from '../../atoms/PortableText'


const SimpleContentGrid: React.FC<SimpleContentGridT> = ({ body, contentBlocks, navanchor }) => {
  return (
    <section className={`max-w-screen-xxl lg:w-6/12 md:w-7/12 w-11/12 mx-auto md:my-10 ${navanchor ? 'pt-10' : ''}`} id={navanchor || ''}>
      <PortableText className="inline text-charcoal" content={body} template={{
        normal: (props: any) => <Text type="P" ptype="p-base" className="text-sm py-3 text-charcoal font-light leading-7">{props?.children}</Text>,
        h3: (props: any) => <Text type="H4" className="font-montserrat font-normal text-2xl mb-3">{props?.children}</Text>,
        h2: (props: any) => <Text type="H4" className="font-montserrat font-normal text-2xl mb-3">{props?.children}</Text>,
      }} />

      <section className="grid md:grid-cols-2 lg:gap-8 gap-4 my-10">
        {contentBlocks?.map((content, index) => 
           <CoreModulesCard  body={content.body} key={index} />
         )}
      </section>
    </section>
  )
}

export default SimpleContentGrid;
