import React from 'react'
import Text from '../../atoms/Text'

interface IFilterList {
  onClick?: () => void
  isSelected: boolean
}

const FilterList: React.FC<IFilterList> = ({ children, onClick, isSelected }) => {

  return (
    <Text type="P" ptype="sm-light"
      onClick={() => {onClick && onClick() }}
      className={`py-1 px-6 border relative cursor-pointer rounded-full m-2 ${isSelected ? 'bg-blue-stone border-blue-stone text-white' : 'border-viking bg-transparent text-viking'}`}>
      <span className={`${isSelected ? '' : 'hidden'} absolute -right-2 -top-2 rounded-full text-blue-stone border-2 border-blue-stone text-center text-xs px-1 font-bold bg-white`}>x</span>
        {children}
    </Text>
  )
}

export default FilterList