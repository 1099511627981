import React from 'react'

export default function IPlatformCombined() {
  return (
    <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>icon/combine</title>
      <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Our-Products-|-Our-Ecosystem" transform="translate(-355.000000, -2787.000000)" fillRule="nonzero">
          <g id="icon/combine" transform="translate(355.000000, 2787.000000)">
            <path d="M47.0625,10.125 L37.875,10.125 L37.875,0.9375 C37.875,0.419677688 37.4553223,0 36.9375,0 L0.9375,0 C0.419677688,0 0,0.419677688 0,0.9375 L0,36.9375 C0,37.4553223 0.419677688,37.875 0.9375,37.875 L10.125,37.875 L10.125,47.0625 C10.125,47.5803223 10.5446777,48 11.0625,48 L25.265625,48 C25.7834473,48 26.203125,47.5803223 26.203125,47.0625 C26.203125,46.5446777 25.7834473,46.125 25.265625,46.125 L12,46.125 L12,37.7962646 C12.3310547,37.6516114 12.5625,37.3216553 12.5625,36.9375 C12.5625,36.4196777 12.1428223,36 11.625,36 L1.875,36 L1.875,1.875 L36,1.875 L36,10.2037354 C35.6689453,10.3483886 35.4375,10.6783448 35.4375,11.0625 C35.4375,11.5803223 35.8571777,12 36.375,12 L46.125,12 L46.125,46.125 L32.8125,46.125 C32.2946777,46.125 31.875,46.5446777 31.875,47.0625 C31.875,47.5803223 32.2946777,48 32.8125,48 L47.0625,48 C47.5803223,48 48,47.5803223 48,47.0625 L48,11.0625 C48,10.5446777 47.5803223,10.125 47.0625,10.125 Z" id="Path" fill="#404040"></path>
            <path d="M36,32.625 C36,33.1428223 36.4196777,33.5625 36.9375,33.5625 C37.4553223,33.5625 37.875,33.1428223 37.875,32.625 L37.875,32.0625 C37.875,31.5446777 37.4553223,31.125 36.9375,31.125 C36.4196777,31.125 36,31.5446777 36,32.0625 L36,32.625 Z" id="Path" fill="#4CC2B8"></path>
            <path d="M36,24 C36,24.5178223 36.4196777,24.9375 36.9375,24.9375 C37.4553223,24.9375 37.875,24.5178223 37.875,24 L37.875,23.4375 C37.875,22.9196777 37.4553223,22.5 36.9375,22.5 C36.4196777,22.5 36,22.9196777 36,23.4375 L36,24 Z" id="Path" fill="#4CC2B8"></path>
            <path d="M36.9375,16.3125 C37.4553223,16.3125 37.875,15.8928223 37.875,15.375 L37.875,14.8125 C37.875,14.2946777 37.4553223,13.875 36.9375,13.875 C36.4196777,13.875 36,14.2946777 36,14.8125 L36,15.375 C36,15.8928223 36.4196777,16.3125 36.9375,16.3125 Z" id="Path" fill="#4CC2B8"></path>
            <path d="M36,28.3125 C36,28.8303223 36.4196777,29.25 36.9375,29.25 C37.4553223,29.25 37.875,28.8303223 37.875,28.3125 L37.875,27.75 C37.875,27.2321777 37.4553223,26.8125 36.9375,26.8125 C36.4196777,26.8125 36,27.2321777 36,27.75 L36,28.3125 Z" id="Path" fill="#4CC2B8"></path>
            <path d="M28.3125,12 C28.8303223,12 29.25,11.5803223 29.25,11.0625 C29.25,10.5446777 28.8303223,10.125 28.3125,10.125 L27.75,10.125 C27.2321777,10.125 26.8125,10.5446777 26.8125,11.0625 C26.8125,11.5803223 27.2321777,12 27.75,12 L28.3125,12 Z" id="Path" fill="#4CC2B8"></path>
            <path d="M28.875,37.875 C29.3928223,37.875 29.8125,37.4553223 29.8125,36.9375 C29.8125,36.4196777 29.3928223,36 28.875,36 L28.3125,36 C27.7946777,36 27.375,36.4196777 27.375,36.9375 C27.375,37.4553223 27.7946777,37.875 28.3125,37.875 L28.875,37.875 Z" id="Path" fill="#4CC2B8"></path>
            <path d="M36,36.9375 C36,37.4553223 36.4196777,37.875 36.9375,37.875 C37.4553223,37.875 37.875,37.4553223 37.875,36.9375 L37.875,36.375 C37.875,35.8571777 37.4553223,35.4375 36.9375,35.4375 C36.4196777,35.4375 36,35.8571777 36,36.375 L36,36.9375 Z" id="Path" fill="#4CC2B8"></path>
            <path d="M32.625,12 C33.1428223,12 33.5625,11.5803223 33.5625,11.0625 C33.5625,10.5446777 33.1428223,10.125 32.625,10.125 L32.0625,10.125 C31.5446777,10.125 31.125,10.5446777 31.125,11.0625 C31.125,11.5803223 31.5446777,12 32.0625,12 L32.625,12 Z" id="Path" fill="#4CC2B8"></path>
            <path d="M32.625,37.875 L33.1875,37.875 C33.7053223,37.875 34.125,37.4553223 34.125,36.9375 C34.125,36.4196777 33.7053223,36 33.1875,36 L32.625,36 C32.1071777,36 31.6875,36.4196777 31.6875,36.9375 C31.6875,37.4553223 32.1071777,37.875 32.625,37.875 Z" id="Path" fill="#4CC2B8"></path>
            <path d="M36,19.6875 C36,20.2053223 36.4196777,20.625 36.9375,20.625 C37.4553223,20.625 37.875,20.2053223 37.875,19.6875 L37.875,19.125 C37.875,18.6071777 37.4553223,18.1875 36.9375,18.1875 C36.4196777,18.1875 36,18.6071777 36,19.125 L36,19.6875 Z" id="Path" fill="#4CC2B8"></path>
            <path d="M19.6875,12 C20.2053223,12 20.625,11.5803223 20.625,11.0625 C20.625,10.5446777 20.2053223,10.125 19.6875,10.125 L19.125,10.125 C18.6071777,10.125 18.1875,10.5446777 18.1875,11.0625 C18.1875,11.5803223 18.6071777,12 19.125,12 L19.6875,12 Z" id="Path" fill="#4CC2B8"></path>
            <path d="M12,19.6875 C12,19.1696777 11.5803223,18.75 11.0625,18.75 C10.5446777,18.75 10.125,19.1696777 10.125,19.6875 L10.125,20.25 C10.125,20.7678223 10.5446777,21.1875 11.0625,21.1875 C11.5803223,21.1875 12,20.7678223 12,20.25 L12,19.6875 Z" id="Path" fill="#4CC2B8"></path>
            <path d="M12,24 C12,23.4821777 11.5803223,23.0625 11.0625,23.0625 C10.5446777,23.0625 10.125,23.4821777 10.125,24 L10.125,24.5625 C10.125,25.0803223 10.5446777,25.5 11.0625,25.5 C11.5803223,25.5 12,25.0803223 12,24.5625 L12,24 Z" id="Path" fill="#4CC2B8"></path>
            <path d="M12,11.0625 C12,10.5446777 11.5803223,10.125 11.0625,10.125 C10.5446777,10.125 10.125,10.5446777 10.125,11.0625 L10.125,11.625 C10.125,12.1428223 10.5446777,12.5625 11.0625,12.5625 C11.5803223,12.5625 12,12.1428223 12,11.625 L12,11.0625 Z" id="Path" fill="#4CC2B8"></path>
            <path d="M12,15.375 C12,14.8571777 11.5803223,14.4375 11.0625,14.4375 C10.5446777,14.4375 10.125,14.8571777 10.125,15.375 L10.125,15.9375 C10.125,16.4553223 10.5446777,16.875 11.0625,16.875 C11.5803223,16.875 12,16.4553223 12,15.9375 L12,15.375 Z" id="Path" fill="#4CC2B8"></path>
            <path d="M11.0625,31.6875 C10.5446777,31.6875 10.125,32.1071777 10.125,32.625 L10.125,33.1875 C10.125,33.7053223 10.5446777,34.125 11.0625,34.125 C11.5803223,34.125 12,33.7053223 12,33.1875 L12,32.625 C12,32.1071777 11.5803223,31.6875 11.0625,31.6875 Z" id="Path" fill="#4CC2B8"></path>
            <path d="M12,28.3125 C12,27.7946777 11.5803223,27.375 11.0625,27.375 C10.5446777,27.375 10.125,27.7946777 10.125,28.3125 L10.125,28.875 C10.125,29.3928223 10.5446777,29.8125 11.0625,29.8125 C11.5803223,29.8125 12,29.3928223 12,28.875 L12,28.3125 Z" id="Path" fill="#4CC2B8"></path>
            <path d="M19.6875,37.875 L20.25,37.875 C20.7678223,37.875 21.1875,37.4553223 21.1875,36.9375 C21.1875,36.4196777 20.7678223,36 20.25,36 L19.6875,36 C19.1696777,36 18.75,36.4196777 18.75,36.9375 C18.75,37.4553223 19.1696777,37.875 19.6875,37.875 Z" id="Path" fill="#4CC2B8"></path>
            <path d="M24,37.875 L24.5625,37.875 C25.0803223,37.875 25.5,37.4553223 25.5,36.9375 C25.5,36.4196777 25.0803223,36 24.5625,36 L24,36 C23.4821777,36 23.0625,36.4196777 23.0625,36.9375 C23.0625,37.4553223 23.4821777,37.875 24,37.875 Z" id="Path" fill="#4CC2B8"></path>
            <path d="M24,12 C24.5178223,12 24.9375,11.5803223 24.9375,11.0625 C24.9375,10.5446777 24.5178223,10.125 24,10.125 L23.4375,10.125 C22.9196777,10.125 22.5,10.5446777 22.5,11.0625 C22.5,11.5803223 22.9196777,12 23.4375,12 L24,12 Z" id="Path" fill="#4CC2B8"></path>
            <path d="M15.375,12 C15.8928223,12 16.3125,11.5803223 16.3125,11.0625 C16.3125,10.5446777 15.8928223,10.125 15.375,10.125 L14.8125,10.125 C14.2946777,10.125 13.875,10.5446777 13.875,11.0625 C13.875,11.5803223 14.2946777,12 14.8125,12 L15.375,12 Z" id="Path" fill="#4CC2B8"></path>
            <path d="M15.375,37.875 L15.9375,37.875 C16.4553223,37.875 16.875,37.4553223 16.875,36.9375 C16.875,36.4196777 16.4553223,36 15.9375,36 L15.375,36 C14.8571777,36 14.4375,36.4196777 14.4375,36.9375 C14.4375,37.4553223 14.8571777,37.875 15.375,37.875 Z" id="Path" fill="#4CC2B8"></path>
            <path d="M29.0625,46.125 C28.8160401,46.125 28.5739746,46.2253417 28.3996583,46.3996583 C28.2253417,46.5739746 28.125,46.8160401 28.125,47.0625 C28.125,47.3089599 28.2253417,47.5510254 28.3996583,47.7253417 C28.5739746,47.8996583 28.8160401,48 29.0625,48 C29.3089599,48 29.5510254,47.8996583 29.7253417,47.7253417 C29.8996583,47.5510254 30,47.3089599 30,47.0625 C30,46.8160401 29.8996583,46.5739746 29.7253417,46.3996583 C29.5510254,46.2253417 29.3089599,46.125 29.0625,46.125 Z" id="Path" fill="#4CC2B8"></path>
          </g>
        </g>
      </g>
    </svg>
  )
}
