import React from 'react'
import { useAppListContext } from '../../../Context/AppListContext';
import Link from '../../atoms/Link';
import { IPlatformCard } from '../../molecules/Card';
import { IPlatformCardProps } from '../../molecules/Card/IPlatformCard';


const PlatformStoreList = () => { 
  const { getApps, storeLength, clearCart } = useAppListContext();

  return (
    <div className="flex flex-col max-w-7xl px-2 sm:px-4 md:px-8 mx-auto">
      <div className="my-12 grid gap-8 grid-cols-1 md:grid-cols-2 xlg:grid-cols-3 justify-center w-max mx-auto" >
         {getApps?.()?.map((rec: IPlatformCardProps) => <IPlatformCard key={rec.uniqueReference} {...rec} className="w-84 mx-auto" hideReadMore hideCategory={false} hidePASIntegrated={false} view="grid" />)}
     </div>
     {!!storeLength && <button 
      disabled={!storeLength} onClick={clearCart}
      type="button" color="btn-secondary" 
      className={`rounded-3xl py-2 px-4 mt-4 mx-auto w-full max-w-xs bg-viking text-white  ${!storeLength ? 'opacity-40 cursor-not-allowed' : ''}`} 
      >
        Clear List
      </button>}
    </div>
  )
}

export default PlatformStoreList;
