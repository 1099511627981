import React from 'react'

export default function IPlatformCategory() {
  return (
    <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>icon/category</title>
      <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Our-Products-|-Our-Ecosystem" transform="translate(-355.000000, -2346.000000)" fillRule="nonzero">
          <g id="icon/category" transform="translate(355.000000, 2346.000000)">
            <path d="M45.6,0 L2.4,0 C1.07514483,0.00151546001 0.00151546001,1.07514483 0,2.4 L0,45.6 C0.00151546001,46.9248552 1.07514483,47.9984845 2.4,48 L45.6,48 C46.9248552,47.9984845 47.9984845,46.9248552 48,45.6 L48,2.4 C47.9984845,1.07514483 46.9248552,0.00151546001 45.6,0 L45.6,0 Z M46.4,45.6 C46.3994947,46.0416183 46.0416183,46.3994947 45.6,46.4 L2.4,46.4 C1.95838169,46.3994947 1.60050534,46.0416183 1.6,45.6 L1.6,2.4 C1.60050534,1.95838169 1.95838169,1.60050534 2.4,1.6 L45.6,1.6 C46.0416183,1.60050534 46.3994947,1.95838169 46.4,2.4 L46.4,45.6 Z" id="Shape" fill="#404040"></path>
            <circle id="Oval" fill="#404040" cx="3.69230769" cy="3.69230769" r="1"></circle>
            <circle id="Oval" fill="#404040" cx="6.64615385" cy="3.69230769" r="1"></circle>
            <circle id="Oval" fill="#404040" cx="8.86153846" cy="3.69230769" r="1"></circle>
            <path d="M22.8923077,19.9384615 L19.9384615,19.9384615 C19.7426076,19.9384615 19.5547738,20.0292218 19.4162842,20.1907931 C19.2777945,20.3523643 19.2,20.5715038 19.2,20.8 L19.2,24.2461538 C19.2,24.4746501 19.2777945,24.6937896 19.4162842,24.8553608 C19.5547738,25.016932 19.7426076,25.1076923 19.9384615,25.1076923 L22.8923077,25.1076923 C23.0881616,25.1076923 23.2759954,25.016932 23.4144851,24.8553608 C23.5529747,24.6937896 23.6307692,24.4746501 23.6307692,24.2461538 L23.6307692,20.8 C23.6307692,20.5715038 23.5529747,20.3523643 23.4144851,20.1907931 C23.2759954,20.0292218 23.0881616,19.9384615 22.8923077,19.9384615 L22.8923077,19.9384615 Z M22.1538462,23.3846154 L20.6769231,23.3846154 L20.6769231,21.6615385 L22.1538462,21.6615385 L22.1538462,23.3846154 Z" id="Shape" fill="#404040"></path>
            <polygon id="Path" fill="#404040" points="26.5846154 21.4153846 28.8 21.4153846 28.8 22.8923077 26.5846154 22.8923077"></polygon>
            <polygon id="Path" fill="#4CC2B8" points="30.2769231 21.4153846 41.3538462 21.4153846 41.3538462 22.8923077 30.2769231 22.8923077"></polygon>
            <path d="M22.8923077,26.5846154 L19.9384615,26.5846154 C19.7426076,26.5846154 19.5547738,26.6753757 19.4162842,26.8369469 C19.2777945,26.9985181 19.2,27.2176576 19.2,27.4461538 L19.2,30.8923077 C19.2,31.1208039 19.2777945,31.3399434 19.4162842,31.5015146 C19.5547738,31.6630859 19.7426076,31.7538462 19.9384615,31.7538462 L22.8923077,31.7538462 C23.0881616,31.7538462 23.2759954,31.6630859 23.4144851,31.5015146 C23.5529747,31.3399434 23.6307692,31.1208039 23.6307692,30.8923077 L23.6307692,27.4461538 C23.6307692,27.2176576 23.5529747,26.9985181 23.4144851,26.8369469 C23.2759954,26.6753757 23.0881616,26.5846154 22.8923077,26.5846154 L22.8923077,26.5846154 Z M22.1538462,30.0307692 L20.6769231,30.0307692 L20.6769231,28.3076923 L22.1538462,28.3076923 L22.1538462,30.0307692 Z" id="Shape" fill="#404040"></path>
            <polygon id="Path" fill="#404040" points="26.5846154 28.0615385 28.8 28.0615385 28.8 29.5384615 26.5846154 29.5384615"></polygon>
            <polygon id="Path" fill="#4CC2B8" points="30.2769231 28.0615385 41.3538462 28.0615385 41.3538462 29.5384615 30.2769231 29.5384615"></polygon>
            <path d="M22.8923077,33.2307692 L19.9384615,33.2307692 C19.7426076,33.2307692 19.5547738,33.3085638 19.4162842,33.4470534 C19.2777945,33.585543 19.2,33.7733769 19.2,33.9692308 L19.2,36.9230769 C19.2,37.1189308 19.2777945,37.3067647 19.4162842,37.4452543 C19.5547738,37.5837439 19.7426076,37.6615385 19.9384615,37.6615385 L22.8923077,37.6615385 C23.0881616,37.6615385 23.2759954,37.5837439 23.4144851,37.4452543 C23.5529747,37.3067647 23.6307692,37.1189308 23.6307692,36.9230769 L23.6307692,33.9692308 C23.6307692,33.7733769 23.5529747,33.585543 23.4144851,33.4470534 C23.2759954,33.3085638 23.0881616,33.2307692 22.8923077,33.2307692 L22.8923077,33.2307692 Z M22.1538462,36.1846154 L20.6769231,36.1846154 L20.6769231,34.7076923 L22.1538462,34.7076923 L22.1538462,36.1846154 Z" id="Shape" fill="#404040"></path>
            <polygon id="Path" fill="#404040" points="26.5846154 34.7076923 28.8 34.7076923 28.8 36.1846154 26.5846154 36.1846154"></polygon>
            <polygon id="Path" fill="#4CC2B8" points="30.2769231 34.7076923 41.3538462 34.7076923 41.3538462 36.1846154 30.2769231 36.1846154"></polygon>
            <path d="M22.8923077,39.1384615 L19.9384615,39.1384615 C19.7426076,39.1384615 19.5547738,39.2292218 19.4162842,39.3907931 C19.2777945,39.5523643 19.2,39.7715038 19.2,40 L19.2,43.4461538 C19.2,43.6746501 19.2777945,43.8937896 19.4162842,44.0553608 C19.5547738,44.216932 19.7426076,44.3076923 19.9384615,44.3076923 L22.8923077,44.3076923 C23.0881616,44.3076923 23.2759954,44.216932 23.4144851,44.0553608 C23.5529747,43.8937896 23.6307692,43.6746501 23.6307692,43.4461538 L23.6307692,40 C23.6307692,39.7715038 23.5529747,39.5523643 23.4144851,39.3907931 C23.2759954,39.2292218 23.0881616,39.1384615 22.8923077,39.1384615 L22.8923077,39.1384615 Z M22.1538462,42.5846154 L20.6769231,42.5846154 L20.6769231,40.8615385 L22.1538462,40.8615385 L22.1538462,42.5846154 Z" id="Shape" fill="#404040"></path>
            <polygon id="Path" fill="#404040" points="26.5846154 40.6153846 28.8 40.6153846 28.8 42.0923077 26.5846154 42.0923077"></polygon>
            <polygon id="Path" fill="#4CC2B8" points="30.2769231 40.6153846 41.3538462 40.6153846 41.3538462 42.0923077 30.2769231 42.0923077"></polygon>
            <path d="M43.5101538,8.12307692 L5.22830769,8.12307692 C5.01678548,8.12307692 4.81392491,8.20778653 4.66435612,8.35858634 C4.51478733,8.50938615 4.43076923,8.71391635 4.43076923,8.92717949 L4.43076923,43.5035897 C4.43076923,43.7168529 4.51478733,43.9213831 4.66435612,44.0721829 C4.81392491,44.2229827 5.01678548,44.3076923 5.22830769,44.3076923 L16.3938462,44.3076923 C16.6053684,44.3076923 16.8082289,44.2229827 16.9577977,44.0721829 C17.1073665,43.9213831 17.1913907,43.7168529 17.1913846,43.5035897 L17.1913846,17.7723077 L43.5101538,17.7723077 C43.7216761,17.7723138 43.9245366,17.6875981 44.0741054,17.5367983 C44.2236742,17.3859985 44.3076923,17.1814683 44.3076923,16.9682051 L44.3076923,8.92717949 C44.3076923,8.71391635 44.2236742,8.50938615 44.0741054,8.35858634 C43.9245366,8.20778653 43.7216761,8.12307692 43.5101538,8.12307692 L43.5101538,8.12307692 Z M15.5963077,42.6994872 L6.02584615,42.6994872 L6.02584615,17.7723077 L15.5963077,17.7723077 L15.5963077,42.6994872 Z M42.7126154,16.1641026 L6.02584615,16.1641026 L6.02584615,9.73128205 L42.7126154,9.73128205 L42.7126154,16.1641026 Z" id="Shape" fill="#4CC2B8"></path>
          </g>
        </g>
      </g>
    </svg>
  )
}
