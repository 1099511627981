import React from 'react'

export default function ProfessionalService() {
  return (
    <svg width="52px" height="52px" viewBox="0 0 52 52" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>icon/Professional-Services </title>
      <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group" transform="translate(0.971111, 0.971130)">
          <path d="M25.4625333,50.9270089 C39.5251933,50.9270089 50.9250667,39.5269182 50.9250667,25.46399 C50.9250667,11.4000907 39.5251933,0 25.4625333,0 C11.3998733,0 0,11.4000907 0,25.46399 C0,39.5269182 11.3998733,50.9270089 25.4625333,50.9270089" id="Fill-1" fill="#056173"></path>
          <path d="M23.2986064,38.4781953 C24.8484998,38.4781953 26.1313376,37.3274067 26.3469242,35.8357516 L31.0441887,35.8357516 L30.4751176,36.4048335 L31.0975998,37.0273276 L32.419282,35.7065913 C32.5047398,35.6201608 32.5474687,35.5075098 32.5474687,35.3948587 C32.5474687,35.2822077 32.5047398,35.1695566 32.419282,35.0840972 L31.0975998,33.7623898 L30.4751176,34.3848839 L31.0441887,34.954937 L26.3469242,34.954937 C26.1313376,33.4632819 24.8484998,32.3115221 23.2986064,32.3115221 C21.599162,32.3115221 20.2153287,33.6953819 20.2153287,35.3948587 C20.2153287,37.0953067 21.599162,38.4781953 23.2986064,38.4781953 Z M23.2986064,33.1923367 C24.5124953,33.1923367 25.5010864,34.1809467 25.5010864,35.3948587 C25.5010864,36.6097419 24.5124953,37.5973807 23.2986064,37.5973807 C22.0847176,37.5973807 21.0961264,36.6097419 21.0961264,35.3948587 C21.0961264,34.1809467 22.0847176,33.1923367 23.2986064,33.1923367 L23.2986064,33.1923367 Z" id="Fill-3" fill="#FFFFFF"></path>
          <path d="M35.6309407,38.4781953 C37.3303851,38.4781953 38.7142184,37.0953067 38.7142184,35.3948587 C38.7142184,33.6953819 37.3303851,32.3115221 35.6309407,32.3115221 C33.9314962,32.3115221 32.5476629,33.6953819 32.5476629,35.3948587 C32.5476629,37.0953067 33.9314962,38.4781953 35.6309407,38.4781953 M35.6309407,33.1923367 C36.8448296,33.1923367 37.8334207,34.1809467 37.8334207,35.3948587 C37.8334207,36.6097419 36.8448296,37.5973807 35.6309407,37.5973807 C34.4170518,37.5973807 33.4284607,36.6097419 33.4284607,35.3948587 C33.4284607,34.1809467 34.4170518,33.1923367 35.6309407,33.1923367" id="Fill-5" fill="#FFFFFF"></path>
          <path d="M21.5368167,22.1815719 C19.9869233,22.1815719 18.7040856,23.3323605 18.4884989,24.8240156 L17.7931833,24.8240156 C14.75749,24.8240156 12.2879544,27.2935982 12.2879544,30.3303206 C12.2879544,33.3660718 14.75749,35.8356545 17.7931833,35.8356545 L18.7118544,35.8356545 L18.1427833,36.4047364 L18.7652656,37.0282016 L20.0859767,35.7064942 C20.1724056,35.6200637 20.2151344,35.5074126 20.2151344,35.3947616 C20.2151344,35.2821106 20.1724056,35.1694595 20.0859767,35.0840001 L18.7652656,33.7622927 L18.1427833,34.3847868 L18.7118544,34.9548399 L17.7931833,34.9548399 C15.2430456,34.9548399 13.1687522,32.8795359 13.1687522,30.3303206 C13.1687522,27.7801342 15.2430456,25.7048302 17.7931833,25.7048302 L18.4884989,25.7048302 C18.7040856,27.1964853 19.9869233,28.348245 21.5368167,28.348245 C23.2362611,28.348245 24.6200944,26.9643853 24.6200944,25.2649084 C24.6200944,23.5644605 23.2362611,22.1815719 21.5368167,22.1815719 Z M21.5368167,27.4674304 C20.3229278,27.4674304 19.3343367,26.4788205 19.3343367,25.2649084 C19.3343367,24.0500253 20.3229278,23.0623864 21.5368167,23.0623864 C22.7507056,23.0623864 23.7392967,24.0500253 23.7392967,25.2649084 C23.7392967,26.4788205 22.7507056,27.4674304 21.5368167,27.4674304 L21.5368167,27.4674304 Z" id="Fill-7" fill="#FFFFFF"></path>
          <path d="M23.1695458,13.5016152 L22.5470636,14.1241093 L23.1161347,14.6941624 L18.4188702,14.6941624 C18.2032836,13.2025073 16.9204458,12.0517187 15.3705524,12.0517187 C13.671108,12.0517187 12.2872747,13.4346073 12.2872747,15.1340841 C12.2872747,16.8345321 13.671108,18.2174207 15.3705524,18.2174207 C16.9204458,18.2174207 18.2032836,17.0666321 18.4188702,15.574977 L23.1161347,15.574977 L22.5470636,16.144059 L23.1695458,16.7675242 L24.491228,15.4458168 C24.5766858,15.3593862 24.6194147,15.2467352 24.6194147,15.1340841 C24.6194147,15.0214331 24.5766858,14.9087821 24.491228,14.8233227 L23.1695458,13.5016152 Z M15.3705524,17.3366061 C14.1566636,17.3366061 13.1680724,16.3489673 13.1680724,15.1340841 C13.1680724,13.9201721 14.1566636,12.9325333 15.3705524,12.9325333 C16.5844413,12.9325333 17.5730324,13.9201721 17.5730324,15.1340841 C17.5730324,16.3489673 16.5844413,17.3366061 15.3705524,17.3366061 L15.3705524,17.3366061 Z" id="Fill-9" fill="#FFFFFF"></path>
          <path d="M38.7140242,20.1995934 C38.7140242,17.1638422 36.2444887,14.6942595 33.2087953,14.6942595 L30.7509131,14.6942595 C30.5362976,13.2026044 29.2524887,12.0508447 27.7025953,12.0508447 C26.0031509,12.0508447 24.6202887,13.4347044 24.6202887,15.1341813 C24.6202887,16.8346292 26.0031509,18.2175178 27.7025953,18.2175178 C29.2524887,18.2175178 30.5362976,17.0667292 30.7509131,15.5750741 L33.2087953,15.5750741 C35.7589331,15.5750741 37.8332264,17.649407 37.8332264,20.1995934 C37.8332264,22.7497798 35.7589331,24.8241127 33.2087953,24.8241127 L26.1235687,24.8241127 L26.6926398,24.2550307 L26.0701576,23.6315655 L24.7484753,24.9532729 C24.6630176,25.0397035 24.6202887,25.1523545 24.6202887,25.2650056 C24.6202887,25.3776566 24.6630176,25.4903076 24.7484753,25.575767 L26.0701576,26.8974745 L26.6926398,26.2749804 L26.1235687,25.7049273 L33.2087953,25.7049273 C36.2444887,25.7049273 38.7140242,23.2353446 38.7140242,20.1995934 M27.7025953,17.3367033 C26.4887064,17.3367033 25.5010864,16.3490644 25.5010864,15.1341813 C25.5010864,13.9202692 26.4887064,12.9326304 27.7025953,12.9326304 C28.9174553,12.9326304 29.9050753,13.9202692 29.9050753,15.1341813 C29.9050753,16.3490644 28.9174553,17.3367033 27.7025953,17.3367033" id="Fill-11" fill="#FFFFFF"></path>
        </g>
      </g>
    </svg>
  )
}
